@font-face {
  font-family: "ArticulatCF";
  src: url(fonts/articulatcf-light.woff2) format("woff2"), url(fonts/articulatcf-light.woff) format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Adobe Clean Bold';
  src: url("../assets/fonts/AdobeClean-Bold.eot");
  src: url("../assets/fonts/AdobeClean-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AdobeClean-Bold.woff2") format("woff2"), url("../assets/fonts/AdobeClean-Bold.woff") format("woff"), url("../assets/fonts/AdobeClean-Bold.ttf") format("truetype"), url("../assets/fonts/AdobeClean-Bold.svg#AdobeClean-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Adobe Clean Regular';
  src: url("../assets/fonts/AdobeClean-Regular.eot");
  src: url("../assets/fonts/AdobeClean-Regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AdobeClean-Regular.woff2") format("woff2"), url("../assets/fonts/AdobeClean-Regular.woff") format("woff"), url("../assets/fonts/AdobeClean-Regular.ttf") format("truetype"), url("../assets/fonts/AdobeClean-Regular.svg#AdobeClean-Regular") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

.bottomNav {
  background-color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  font-size: 0.8rem;
  max-width: 1140px;
  margin: 0 auto;
  padding: 4rem 0; }
  @media screen and (max-width: 1140px) {
    .bottomNav {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media screen and (max-width: 860px) {
    .bottomNav {
      -ms-flex-direction: column;
          flex-direction: column; } }
  @media screen and (max-width: 440px) {
    .bottomNav {
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center; } }
  .bottomNav ul {
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    @media screen and (max-width: 860px) {
      .bottomNav ul {
        margin-top: 1rem; } }
    @media screen and (max-width: 830px) {
      .bottomNav ul {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
    @media screen and (max-width: 440px) {
      .bottomNav ul {
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: center;
            justify-content: center;
        width: 80%;
        margin: 0 auto;
        margin-top: 1rem; } }
    .bottomNav ul li {
      color: #2c2c2c;
      padding-right: 1rem;
      margin-right: 0.85rem;
      position: relative;
      font-size: 0.65rem; }
      .bottomNav ul li:after {
        content: '/';
        position: absolute;
        right: 0;
        top: 0; }
      .bottomNav ul li:last-of-type {
        margin-right: 0;
        padding-right: 0rem; }
        .bottomNav ul li:last-of-type:after {
          content: ''; }
      .bottomNav ul li a {
        color: #2c2c2c;
        text-decoration: none;
        transition: all 0.3s ease; }
        .bottomNav ul li a:hover {
          transition: all 0.3s ease;
          text-decoration: underline; }
      @media screen and (max-width: 440px) {
        .bottomNav ul li:nth-child(2) {
          padding-right: 0;
          margin-right: 0; }
          .bottomNav ul li:nth-child(2):after {
            content: ''; }
        .bottomNav ul li:nth-child(4) {
          padding-right: 0;
          margin-right: 0; }
          .bottomNav ul li:nth-child(4):after {
            content: ''; } }
  .bottomNav nav img {
    width: 10rem; }
  .bottomNav p {
    color: #ffffff;
    font-size: .8rem; }
  @media screen and (max-width: 640px) {
    .bottomNav {
      padding: 3rem 0.75rem;
      display: block;
      text-align: center; }
      .bottomNav nav {
        /*padding-bottom: 1rem;*/ } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    .bottomNav {
      padding: 2rem 2.5rem; } }
  @media screen and (min-width: 901px) {
    .bottomNav {
      /*padding: 4rem $padding-sides-normal;*/ } }

div.masthead {
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  left: 50%;
  -ms-transform: translateX(-50%);
      transform: translateX(-50%);
  width: 100%;
  z-index: 4;
  /*max-width:1580px;*/ }
  div.masthead .container {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center; }
    @media screen and (max-width: 1140px) {
      div.masthead .container {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media screen and (max-width: 470px) {
      div.masthead .container {
        padding-left: 0rem;
        padding-right: 0rem; } }
    @media screen and (max-width: 440px) {
      div.masthead .container {
        /*flex-direction: column;*/
        -ms-flex-align: center;
            align-items: center; } }
  div.masthead .Logo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    div.masthead .Logo img, div.masthead .Logo svg {
      height: 30px;
      margin-right: 0.5rem; }
    div.masthead .Logo > div {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
          justify-content: space-between;
      -ms-flex-align: center;
          align-items: center; }
    div.masthead .Logo p {
      font-family: 'Adobe Clean Bold';
      color: #f81004; }
  div.masthead .logoMenu {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    div.masthead .logoMenu a {
      text-decoration: none; }
      div.masthead .logoMenu a div {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center; }
  div.masthead .responsive-menu {
    display: none;
    width: 40px; }
    div.masthead .responsive-menu svg {
      width: 100%; }
    @media screen and (max-width: 660px) {
      div.masthead .responsive-menu {
        display: block;
        position: fixed;
        top: 1.5rem;
        right: 1.5rem;
        z-index: 2001; }
        div.masthead .responsive-menu svg rect {
          fill: black; }
          div.masthead .responsive-menu svg rect#top-rect {
            transition: all 0.3s ease; }
          div.masthead .responsive-menu svg rect#bottom-rect {
            transition: all 0.3s ease; }
        .responsive-active div.masthead .responsive-menu svg rect {
          fill: white; }
          .responsive-active div.masthead .responsive-menu svg rect#top-rect {
            -ms-transform: rotate(45deg) translateY(15px);
                transform: rotate(45deg) translateY(15px);
            -ms-transform-origin: center;
                transform-origin: center;
            transition: all 0.3s ease; }
          .responsive-active div.masthead .responsive-menu svg rect#middle-rect {
            display: none; }
          .responsive-active div.masthead .responsive-menu svg rect#bottom-rect {
            -ms-transform: rotate(-45deg) translateY(-23px) translateX(4px);
                transform: rotate(-45deg) translateY(-23px) translateX(4px);
            -ms-transform-origin: center;
                transform-origin: center;
            transition: all 0.3s ease; } }
    @media screen and (max-width: 640px) {
      div.masthead .responsive-menu {
        top: 1rem; } }
  div.masthead ul {
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    margin-left: 2rem; }
    div.masthead ul li {
      margin-right: 1rem; }
      div.masthead ul li:last-of-type {
        margin-right: 0; }
      div.masthead ul li a {
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 15px;
        text-decoration: none;
        color: #3D494E;
        transition: all 0.3s ease; }
        div.masthead ul li a:hover {
          transition: all 0.3s ease;
          color: #00b3e8; }
        @media screen and (max-width: 1032px) {
          div.masthead ul li a {
            font-size: 12px; } }
        @media screen and (max-width: 1032px) {
          div.masthead ul li a {
            line-height: 12px; } }
        @media screen and (max-width: 660px) {
          div.masthead ul li a {
            width: 100%;
            color: white;
            border-bottom: 1px solid white;
            display: block;
            text-align: center;
            padding-bottom: 1rem;
            padding-top: 1rem;
            font-size: 16px; } }
      @media screen and (max-width: 900px) {
        div.masthead ul li {
          line-height: 12px; } }
      @media screen and (max-width: 660px) {
        div.masthead ul li {
          width: 100%; } }
    @media screen and (max-width: 660px) {
      div.masthead ul {
        position: fixed;
        left: 100%;
        top: 0;
        height: 100vh;
        width: 100%;
        z-index: 2000;
        margin: 0;
        transition: all 0.3s ease;
        background: rgba(0, 0, 0, 0.8);
        -ms-flex-direction: column;
            flex-direction: column;
        padding-top: 6rem; }
        .responsive-active div.masthead ul {
          left: 0;
          transition: all 0.3s ease; } }
  div.masthead .ContactUsBtn {
    -ms-flex-item-align: end;
        align-self: flex-end; }
    div.masthead .ContactUsBtn a {
      font-family: 'Adobe Clean Bold';
      font-size: 13px;
      font-size: 14px;
      border-radius: 2rem;
      padding: 0.5rem 1rem 0.65rem;
      color: #ffffff;
      display: block;
      text-align: center;
      background: #1473E6;
      /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#3688eb",GradientType=1);*/
      border: 1px solid #1473E6;
      text-transform: uppercase;
      text-decoration: none;
      transition: all 0.3s ease;
      font-family: 'Adobe Clean Bold';
      font-size: 14px;
      border-radius: 2rem;
      padding: 0.5rem 1rem;
      text-transform: none;
      transition: all 0.3s ease; }
      div.masthead .ContactUsBtn a:hover {
        transition: all 0.3s ease;
        background: #0d66d0;
        border: 1px solid #0d66d0;
        /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#1373e4",GradientType=1);*/ }
      div.masthead .ContactUsBtn a:hover {
        transition: all 0.3s ease; }
      @media screen and (max-width: 1032px) {
        div.masthead .ContactUsBtn a {
          /*font-size: 12px;*/
          /*padding: 16px 16px 16px;*/ } }
      @media screen and (max-width: 900px) {
        div.masthead .ContactUsBtn a {
          /*font-size: 1.1vw;*/
          /*padding: 1vw 1.1vw;*/ } }
      @media screen and (max-width: 660px) {
        div.masthead .ContactUsBtn a {
          /*font-size: 11px;*/
          /*padding: 10px 15px;*/
          /*margin-right: 60px;*/
          width: 100%; } }
      @media screen and (max-width: 440px) {
        div.masthead .ContactUsBtn a {
          /*max-width: 120px;*/
          /*align-self: center;*/ } }
    @media screen and (max-width: 440px) {
      div.masthead .ContactUsBtn {
        /*max-width: 120px;*/
        -ms-flex-item-align: center;
            -ms-grid-row-align: center;
            align-self: center;
        /*margin-top:1rem*/ } }
  @media screen and (max-width: 640px) {
    div.masthead {
      padding: 1rem 1.5rem;
      /*.Logo {
            img, svg {
                width: 8rem;
            }
        }*/
      /*.ContactUsBtn {
            display: none;
        }*/ } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    div.masthead {
      padding: 2rem 1rem;
      /*.Logo {
            img, svg {
                width: 10rem;
            }
        }*/
      /*.ContactUsBtn {
            display: none;
        }*/ } }
  @media screen and (min-width: 901px) and (max-width: 1200px) {
    div.masthead {
      /*.ContactUsBtn {
            display: none;
        }*/ } }
  @media screen and (min-width: 901px) {
    div.masthead {
      padding: 1.5rem 0rem; } }
  @media screen and (max-width: 375px) {
    div.masthead {
      padding: 1rem 0.75rem; } }

section.section-1 {
  margin-top: 82px; }
  @media screen and (max-width: 640px) {
    section.section-1 {
      margin-top: 67px; } }
  section.section-1 .container {
    max-width: 1580px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    background: transparent url("../images/workfront/banner.jpg") no-repeat center;
    background-size: cover; }
    section.section-1 .container .left {
      /*background: transparent url('https://go.proofpoint.com/rs/309-RHV-619/images/section1-left-BG.jpg') no-repeat center;
			background-size: cover;*/
      width: 50%;
      padding: 5vw;
      padding: 17vw 2vw 17vw 14vw; }
      @media screen and (max-width: 767px) {
        section.section-1 .container .left {
          width: 100%; } }
      section.section-1 .container .left h1 {
        font-family: 'Adobe Clean Bold';
        font-size: 3.6vw;
        line-height: 3.95vw;
        color: white;
        font-weight: normal;
        margin-bottom: 2rem;
        /*@media screen and (max-width: 660px) {
					font-size: 6.5vw;
					margin-bottom: 1.5rem;
					br {
						display: none;
					}
				}*/ }
        section.section-1 .container .left h1 span {
          color: #33AEE1; }
        @media screen and (min-width: 1580px) {
          section.section-1 .container .left h1 {
            font-size: 3.6rem;
            line-height: 3.95rem; } }
        @media screen and (max-width: 767px) {
          section.section-1 .container .left h1 {
            font-size: 2.1rem;
            line-height: 2.4rem; } }
        @media screen and (max-width: 470px) {
          section.section-1 .container .left h1 {
            font-size: 1.75rem;
            line-height: 2rem; } }
        section.section-1 .container .left h1 h1, section.section-1 .container .left h1 h2, section.section-1 .container .left h1 h3, section.section-1 .container .left h1 h4, section.section-1 .container .left h1 h5, section.section-1 .container .left h1 h6, section.section-1 .container .left h1 p {
          font-family: 'Fjalla One', sans-serif;
          font-size: 3.5vw;
          color: white;
          font-weight: normal;
          margin-bottom: 2.5rem; }
          section.section-1 .container .left h1 h1 span, section.section-1 .container .left h1 h2 span, section.section-1 .container .left h1 h3 span, section.section-1 .container .left h1 h4 span, section.section-1 .container .left h1 h5 span, section.section-1 .container .left h1 h6 span, section.section-1 .container .left h1 p span {
            color: #33AEE1; }
          @media screen and (min-width: 1360px) {
            section.section-1 .container .left h1 h1, section.section-1 .container .left h1 h2, section.section-1 .container .left h1 h3, section.section-1 .container .left h1 h4, section.section-1 .container .left h1 h5, section.section-1 .container .left h1 h6, section.section-1 .container .left h1 p {
              font-size: 3rem; } }
          @media screen and (max-width: 660px) {
            section.section-1 .container .left h1 h1, section.section-1 .container .left h1 h2, section.section-1 .container .left h1 h3, section.section-1 .container .left h1 h4, section.section-1 .container .left h1 h5, section.section-1 .container .left h1 h6, section.section-1 .container .left h1 p {
              font-size: 6.5vw;
              margin-bottom: 1.5rem; }
              section.section-1 .container .left h1 h1 br, section.section-1 .container .left h1 h2 br, section.section-1 .container .left h1 h3 br, section.section-1 .container .left h1 h4 br, section.section-1 .container .left h1 h5 br, section.section-1 .container .left h1 h6 br, section.section-1 .container .left h1 p br {
                display: none; } }
      section.section-1 .container .left #section1Copy h2, section.section-1 .container .left #section1Copy h3, section.section-1 .container .left #section1Copy h4, section.section-1 .container .left #section1Copy h5, section.section-1 .container .left #section1Copy h6 {
        font-family: 'Fjalla One', sans-serif;
        color: white; }
      section.section-1 .container .left #section1Copy ul {
        margin-left: 1.25rem; }
        section.section-1 .container .left #section1Copy ul li {
          font-family: 'Roboto', sans-serif;
          font-size: 17px;
          font-size: 1.2vw;
          color: white;
          margin-bottom: 1rem; }
          @media screen and (min-width: 1360px) {
            section.section-1 .container .left #section1Copy ul li {
              font-size: 16px; } }
          @media screen and (max-width: 660px) {
            section.section-1 .container .left #section1Copy ul li {
              font-size: 16px; } }
      section.section-1 .container .left p {
        font-family: 'Adobe Clean Regular';
        font-size: 1.65vw;
        color: white;
        margin-bottom: 1rem; }
        @media screen and (min-width: 1580px) {
          section.section-1 .container .left p {
            font-size: 1.65rem;
            line-height: 1.85rem;
            font-style: normal;
            font-weight: 400; } }
        @media screen and (max-width: 660px) {
          section.section-1 .container .left p {
            font-size: 16px; } }
        @media screen and (max-width: 470px) {
          section.section-1 .container .left p {
            width: 49%; } }
      @media screen and (min-width: 1580px) {
        section.section-1 .container .left {
          padding: 17rem 5rem 17rem 14rem; } }
      @media screen and (max-width: 660px) {
        section.section-1 .container .left {
          -ms-flex-order: 1;
              order: 1;
          width: 100%; } }
      @media screen and (max-width: 630px) {
        section.section-1 .container .left {
          padding-left: 3rem; } }
      @media screen and (max-width: 470px) {
        section.section-1 .container .left {
          padding-left: 1.5rem; } }
    section.section-1 .container .right {
      width: 50%; }
      section.section-1 .container .right img {
        width: 100%;
        display: block; }
      @media screen and (max-width: 660px) {
        section.section-1 .container .right {
          -ms-flex-order: 2;
              order: 2;
          width: 100%; } }
      section.section-1 .container .right #mkto_gen_section1-image {
        display: block; }
    section.section-1 .container .footer {
      width: 100%;
      background: #01579b;
      background: linear-gradient(90deg, #01579b 0%, #0290cb 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01579b",endColorstr="#0290cb",GradientType=1); }
      section.section-1 .container .footer h2 {
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-size: 2vw;
        text-align: center;
        color: white;
        padding: 1.5rem 0; }
        @media screen and (min-width: 1360px) {
          section.section-1 .container .footer h2 {
            font-size: 1.7rem; } }
        @media screen and (max-width: 660px) {
          section.section-1 .container .footer h2 {
            font-size: 1.5rem; } }
        @media screen and (max-width: 640px) {
          section.section-1 .container .footer h2 {
            font-size: 20px; } }
      @media screen and (max-width: 660px) {
        section.section-1 .container .footer {
          -ms-flex-order: 3;
              order: 3; } }
    @media screen and (max-width: 660px) {
      section.section-1 .container {
        -ms-flex-direction: column;
            flex-direction: column; } }

body > header {
  padding-top: 7.5rem;
  text-align: center;
  color: #ffffff; }
  body > header .headerLeft {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    -ms-flex-align: center;
        align-items: center;
    min-height: 58rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    position: relative; }
    body > header .headerLeft.retail {
      min-height: 40rem; }
    body > header .headerLeft .top-gradient {
      position: absolute;
      top: 0;
      background: linear-gradient(#0a0a0a, rgba(10, 10, 10, 0));
      height: 360px;
      width: 100%; }
    body > header .headerLeft .btm-gradient {
      position: absolute;
      bottom: 0;
      height: 360px;
      width: 100%;
      background: linear-gradient(rgba(10, 10, 10, 0), #0a0a0a); }
    body > header .headerLeft .container {
      z-index: 1; }
      body > header .headerLeft .container .output {
        text-align: center;
        font-family: monospace;
        color: #ffffff; }
        body > header .headerLeft .container .output h2 {
          font-size: 1.4rem;
          font-weight: 400; }
        body > header .headerLeft .container .output .cursor {
          height: 1.4rem; }

@keyframes blink {
  0% {
    opacity: 1; }
  49% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }
    body > header .headerLeft .wrapper {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: center;
          align-items: center;
      z-index: 1; }
      body > header .headerLeft .wrapper h1 {
        padding-top: 1.75rem;
        letter-spacing: 1px;
        font-size: 4rem;
        font-weight: 600;
        line-height: 5.6rem;
        text-align: center;
        color: #ffffff; }
      body > header .headerLeft .wrapper h3 {
        font-family: 'Courier Prime', monospace;
        font-size: 26px;
        font-weight: 400;
        font-style: normal;
        color: #ffffff;
        margin-top: 20px;
        padding-bottom: 3rem; }
      body > header .headerLeft .wrapper .button {
        font-family: 'Adobe Clean Bold';
        font-size: 13px;
        font-size: 14px;
        border-radius: 2rem;
        padding: 0.5rem 1rem 0.65rem;
        color: #ffffff;
        display: block;
        text-align: center;
        background: #1473E6;
        /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#3688eb",GradientType=1);*/
        border: 1px solid #1473E6;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.3s ease;
        padding: 16px 29px 18px 29px;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 2px; }
        body > header .headerLeft .wrapper .button:hover {
          transition: all 0.3s ease;
          background: #0d66d0;
          border: 1px solid #0d66d0;
          /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#1373e4",GradientType=1);*/ }
      body > header .headerLeft .wrapper p {
        padding-top: 3.25rem;
        font-size: 1.2rem;
        line-height: 1.9rem;
        font-weight: normal;
        width: 70%; }
  @media screen and (max-width: 640px) {
    body > header {
      padding-top: 3.5rem;
      display: block;
      -ms-flex-direction: column;
          flex-direction: column; }
      body > header .headerLeft {
        min-height: 45rem;
        padding: 3rem 0.75rem; }
        body > header .headerLeft .wrapper h1 {
          font-size: 2rem;
          line-height: 2.4rem; }
        body > header .headerLeft .wrapper h3 {
          line-height: 2rem; }
        body > header .headerLeft .wrapper .button {
          width: unset;
          padding: 1rem 1.5rem; }
        body > header .headerLeft .wrapper p {
          font-size: .9rem;
          width: 90%; }
        body > header .headerLeft .output h2 {
          font-size: 1.1rem; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body > header {
      display: block;
      -ms-flex-direction: column;
          flex-direction: column; }
      body > header .headerLeft {
        padding: 3rem 2.5rem; }
      body > header .headerRight {
        display: block; } }
  @media screen and (min-width: 901px) {
    body > header .headerLeft {
      padding: 3rem calc(50vw - 40rem); } }
  @media screen and (min-width: 2000px) {
    body > header .headerLeft h1 {
      width: 80%; } }

.article-cta-with-image {
  display: -ms-flexbox;
  display: flex;
  background-position: bottom;
  background-size: cover;
  min-height: 35rem;
  text-align: center;
  color: #ffffff; }
  .article-cta-with-image.article2 {
    position: relative;
    background-image: url("../images/UNUM-1-1.jpg"); }
    .article-cta-with-image.article2.retail {
      background-image: url("../images/co-cars.jpeg"); }
      .article-cta-with-image.article2.retail .headerLeft .wrap a {
        background: #e2e367; }
    .article-cta-with-image.article2 .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3); }
    .article-cta-with-image.article2 .headerLeft {
      /*background-color: rgba(0,0,0,0.3);*/ }
      .article-cta-with-image.article2 .headerLeft .wrap h3 {
        color: #ffffff; }
      .article-cta-with-image.article2 .headerLeft .wrap p {
        color: #ffffff; }
    .article-cta-with-image.article2 .headerRight {
      /*background-color: rgba(0,0,0,0.3);*/
      position: relative; }
      .article-cta-with-image.article2 .headerRight .play {
        position: absolute;
        width: 30%;
        left: 50%;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }
    .article-cta-with-image.article2.video {
      -ms-flex-align: center;
          align-items: center; }
      .article-cta-with-image.article2.video .headerRight {
        -ms-flex-align: start;
            align-items: flex-start; }
        @media screen and (min-width: 641px) and (max-width: 900px) {
          .article-cta-with-image.article2.video .headerRight {
            -ms-flex-pack: start;
                justify-content: flex-start;
            padding-top: 2rem;
            padding-bottom: 2rem; } }
        .article-cta-with-image.article2.video .headerRight a {
          position: relative; }
          @media screen and (min-width: 641px) and (max-width: 900px) {
            .article-cta-with-image.article2.video .headerRight a {
              margin-left: 4rem; } }
          @media screen and (max-width: 640px) {
            .article-cta-with-image.article2.video .headerRight a {
              margin-left: 0rem; } }
        .article-cta-with-image.article2.video .headerRight img {
          margin-top: 0;
          border: 3px solid white; }
          .article-cta-with-image.article2.video .headerRight img.play {
            border: 0; }
          @media screen and (min-width: 641px) and (max-width: 900px) {
            .article-cta-with-image.article2.video .headerRight img {
              width: auto;
              height: auto; } }
          @media screen and (max-width: 640px) {
            .article-cta-with-image.article2.video .headerRight img {
              width: 100%; } }
        .article-cta-with-image.article2.video .headerRight .play {
          width: 30%;
          left: 50%;
          top: 50%; }
  .article-cta-with-image .headerLeft {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    text-align: left;
    /*margin-right: 4rem;*/ }
    .article-cta-with-image .headerLeft .wrap {
      padding-left: 1rem; }
      .article-cta-with-image .headerLeft .wrap img {
        width: 8.5rem;
        padding-bottom: 2rem; }
      .article-cta-with-image .headerLeft .wrap h3 {
        font-size: 2rem;
        color: #414141;
        font-weight: 600;
        text-transform: none;
        line-height: 2rem;
        padding-top: 2rem; }
      .article-cta-with-image .headerLeft .wrap p {
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 1.6rem;
        text-align: left;
        padding: 1.5rem 5rem 4rem 0rem;
        color: #414141; }
      .article-cta-with-image .headerLeft .wrap a {
        font-family: 'Adobe Clean Bold';
        font-size: 13px;
        font-size: 14px;
        border-radius: 2rem;
        padding: 0.5rem 1rem 0.65rem;
        color: #ffffff;
        display: block;
        text-align: center;
        background: #1473E6;
        /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#3688eb",GradientType=1);*/
        border: 1px solid #1473E6;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.3s ease;
        width: 35%; }
        .article-cta-with-image .headerLeft .wrap a:hover {
          transition: all 0.3s ease;
          background: #0d66d0;
          border: 1px solid #0d66d0;
          /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#1373e4",GradientType=1);*/ }
  .article-cta-with-image .headerRight {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    -ms-flex-pack: end;
        justify-content: flex-end; }
    .article-cta-with-image .headerRight img {
      z-index: 3;
      margin-top: -3rem;
      max-width: 438px;
      height: auto; }
  @media screen and (max-width: 640px) {
    .article-cta-with-image {
      display: block;
      -ms-flex-direction: column;
          flex-direction: column; }
      .article-cta-with-image .headerLeft {
        padding: 5rem 0.75rem; }
        .article-cta-with-image .headerLeft .wrap h1 {
          font-size: 1.6rem;
          line-height: 2rem; }
        .article-cta-with-image .headerLeft .wrap h2 {
          padding-right: 0; }
        .article-cta-with-image .headerLeft .wrap p {
          padding-right: 0;
          padding-right: 0; }
      .article-cta-with-image .headerRight {
        padding-left: 2rem;
        padding-bottom: 2rem;
        -ms-flex-pack: start;
            justify-content: flex-start; }
        .article-cta-with-image .headerRight img {
          width: 80%; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    .article-cta-with-image {
      display: block;
      -ms-flex-direction: column;
          flex-direction: column; }
      .article-cta-with-image .headerLeft {
        padding: 4rem 2.5rem; }
      .article-cta-with-image .headerRight {
        -ms-flex-pack: center;
            justify-content: center;
        padding-top: 5rem; }
        .article-cta-with-image .headerRight img {
          width: 500px;
          height: 625px; } }
  @media screen and (min-width: 901px) {
    .article-cta-with-image .headerLeft {
      padding: 6rem calc(50vw - 610px);
      padding-right: 0;
      padding-bottom: 5rem; }
      .article-cta-with-image .headerLeft h1 {
        font-size: 2.4rem; }
    .article-cta-with-image .headerRight {
      padding: 5rem calc(50vw - 610px);
      padding-left: 0;
      padding-top: 0; } }

.image-with-article-cta {
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  color: #ffffff; }
  .image-with-article-cta .headerLeft {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 43%;
        flex: 0 0 43%; }
    .image-with-article-cta .headerLeft img {
      width: 100%;
      height: fit-content; }
  .image-with-article-cta .headerRight {
    -ms-flex: 0 0 57%;
        flex: 0 0 57%;
    background-image: url("https://pages.hitachivantara.com/rs/375-JOE-551/images/headerLeftBg.png");
    background-size: cover;
    text-align: left; }
    .image-with-article-cta .headerRight h4 {
      color: #cc0000;
      font-weight: 600; }
    .image-with-article-cta .headerRight h2 {
      font-size: 1.4rem;
      color: #ffffff;
      line-height: 2rem;
      font-weight: 300;
      padding-top: 1rem;
      width: 55%; }
    .image-with-article-cta .headerRight p {
      font-weight: 300;
      text-align: left;
      padding-top: 1rem;
      padding-bottom: 2rem;
      width: 60%; }
    .image-with-article-cta .headerRight a {
      display: block;
      width: 220px;
      text-align: center;
      background: #cc0000;
      border: 1px solid #cc0000;
      padding: 1rem;
      font-size: .9rem;
      text-decoration: none;
      color: #fff;
      font-weight: 400; }
      .image-with-article-cta .headerRight a:hover {
        background: #fff;
        color: #cc0000;
        border: 1px solid #cc0000; }
  @media screen and (max-width: 640px) {
    .image-with-article-cta {
      display: block;
      -ms-flex-direction: column;
          flex-direction: column; }
      .image-with-article-cta .headerRight {
        padding: 5rem 0.75rem; }
        .image-with-article-cta .headerRight h1 {
          font-size: 1.6rem;
          line-height: 2rem; }
        .image-with-article-cta .headerRight h2 {
          width: 90%; }
        .image-with-article-cta .headerRight p {
          width: 100%; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    .image-with-article-cta {
      display: block;
      -ms-flex-direction: column;
          flex-direction: column; }
      .image-with-article-cta .headerRight {
        padding: 3rem 2.5rem; } }
  @media screen and (min-width: 901px) {
    .image-with-article-cta .headerRight {
      padding: 3rem 0 3rem 6rem; }
      .image-with-article-cta .headerRight h1 {
        font-size: 2.4rem; } }
  @media screen and (min-width: 2000px) {
    .image-with-article-cta .headerRight {
      padding: 5rem 0 5rem 4rem; }
      .image-with-article-cta .headerRight h1 {
        font-size: 2.4rem; }
      .image-with-article-cta .headerRight p {
        width: 40%; } }

body > main {
  text-align: center;
  color: #414141; }
  body > main img, body > main iframe {
    box-shadow: 0px 0px 143px 0px rgba(0, 0, 0, 0.4);
    display: block;
    margin: -16.5rem auto 6.25rem auto; }
  body > main .videos {
    margin: -16.5rem auto 6.25rem auto; }
  body > main p {
    font-size: 0.965rem;
    line-height: 1.3rem;
    color: #414141; }
  @media screen and (max-width: 640px) {
    body > main {
      padding: 2.25rem 0.75rem 1rem; }
      body > main img, body > main iframe {
        width: 100%;
        margin: 0 auto 6.25rem auto; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body > main {
      padding: 2.25rem 2.5rem 1rem; }
      body > main img, body > main iframe {
        width: 100%;
        margin: 0 auto 6.25rem auto; } }
  @media screen and (min-width: 901px) {
    body > main {
      padding: 2.25rem calc(50vw - 610px) 5rem calc(50vw - 610px); }
      body > main:after {
        content: " ";
        visibility: hidden;
        display: block;
        font-size: 0;
        clear: both;
        height: 0; } }

body aside.three-col {
  background-color: #ffffff;
  text-align: center;
  color: #414141; }
  body aside.three-col h1 {
    font-size: 1.6rem;
    text-align: center;
    padding-bottom: 3rem;
    font-weight: 300; }
    body aside.three-col h1 span {
      font-weight: 400; }
  body aside.three-col h3 {
    color: #cc0000;
    font-size: 1.5rem;
    margin-bottom: 6rem; }
  body aside.three-col .flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    text-align: left; }
    body aside.three-col .flex article {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex: 0 0 31%;
          flex: 0 0 31%;
      box-shadow: 10px 5px 20px 5px rgba(0, 0, 0, 0.1);
      font-size: 0.85rem;
      padding-bottom: 2rem; }
      body aside.three-col .flex article .text {
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
        padding: 2rem 1.75rem 0; }
        body aside.three-col .flex article .text h2 {
          color: #cc0000;
          font-size: 1.1rem;
          font-weight: bold;
          padding-bottom: 1rem;
          padding-right: 2rem; }
        body aside.three-col .flex article .text p {
          padding-top: .2rem;
          color: #000000;
          font-size: .9rem;
          line-height: 1.4rem;
          font-weight: 300; }
      body aside.three-col .flex article a {
        display: block;
        font-size: .95rem;
        color: #cc0000;
        font-weight: bold;
        text-decoration: none;
        margin-top: 1rem;
        padding-left: 1.75rem; }
  @media screen and (max-width: 640px) {
    body aside.three-col {
      padding: 2rem 2.5rem; }
      body aside.three-col.three-col2 {
        padding: 8rem 2.5rem; }
      body aside.three-col .flex {
        display: block; }
        body aside.three-col .flex article {
          margin-bottom: 2rem; }
          body aside.three-col .flex article:last-child {
            margin-bottom: 0; }
          body aside.three-col .flex article .image a img {
            width: 100%; }
            body aside.three-col .flex article .image a img.video {
              width: 100%; }
          body aside.three-col .flex article .image-vid {
            margin-top: -4rem; }
            body aside.three-col .flex article .image-vid img.video {
              width: 80%; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body aside.three-col {
      padding: 3rem 2.5rem; }
      body aside.three-col.three-col2 {
        padding: 8rem 2.5rem; }
      body aside.three-col .flex {
        display: block; }
        body aside.three-col .flex article {
          margin-bottom: 2rem; }
          body aside.three-col .flex article:last-child {
            margin-bottom: 0; }
          body aside.three-col .flex article .image a img {
            width: 100%; }
          body aside.three-col .flex article .image-vid img.video {
            width: 80%; } }
  @media screen and (min-width: 901px) {
    body aside.three-col {
      padding: 4rem calc(50vw - 610px); }
      body aside.three-col.three-col2 {
        padding: 7rem calc(50vw - 610px) 3rem; } }

body section.three-col2 {
  background-color: #ffffff;
  text-align: center;
  color: #414141; }
  body section.three-col2 .flex {
    text-align: left;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    body section.three-col2 .flex article {
      -ms-flex: 0 0 31%;
          flex: 0 0 31%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      box-shadow: 10px 5px 20px 5px rgba(0, 0, 0, 0.1);
      font-size: 0.85rem;
      padding-bottom: 2rem;
      margin: 0 10px; }
      body section.three-col2 .flex article .mktoText a {
        width: 100%; }
      body section.three-col2 .flex article .mktoText img {
        width: 100%;
        height: auto; }
      body section.three-col2 .flex article .text {
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
        padding: 2rem 1.75rem 0; }
        body section.three-col2 .flex article .text h2 {
          color: #cc0000;
          font-size: 1rem;
          font-weight: 600;
          padding-bottom: 1rem;
          padding-right: 2rem; }
        body section.three-col2 .flex article .text p {
          padding-top: .2rem;
          color: #000;
          font-size: .9rem;
          line-height: 1.4rem; }
      body section.three-col2 .flex article a.cta {
        display: block;
        font-size: 1rem;
        color: #cc0000;
        font-weight: 600;
        text-decoration: none;
        margin-top: 1rem;
        padding-left: 1.75rem; }
  @media screen and (max-width: 640px) {
    body section.three-col2 {
      padding: 2rem 2.5rem; }
      body section.three-col2 .flex {
        display: block; }
        body section.three-col2 .flex article {
          margin-bottom: 4rem; }
          body section.three-col2 .flex article:last-child {
            margin-bottom: 0; }
          body section.three-col2 .flex article .image a img {
            width: 100%; }
            body section.three-col2 .flex article .image a img.video {
              width: 100%; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body section.three-col2 {
      padding: 3rem 2.5rem; }
      body section.three-col2 .flex {
        display: block; }
        body section.three-col2 .flex article {
          margin-bottom: 4rem; }
          body section.three-col2 .flex article:last-child {
            margin-bottom: 0; }
          body section.three-col2 .flex article .image-vid img.video {
            width: 80%; } }
  @media screen and (min-width: 901px) {
    body section.three-col2 {
      padding: 4rem calc(50vw - 610px); } }

/* The Modal (background) */
.modal {
  text-align: left;
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 4;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

/* Modal Content */
.modal-content {
  background: rgba(0, 0, 0, 0.9);
  margin: auto;
  padding: 20px;
  width: 100%;
  height: 100%;
  /* The Close Button */ }
  .modal-content .modalContainer {
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center; }
    .modal-content .modalContainer .header {
      max-height: 1306px;
      width: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: center;
          align-items: center; }
      .modal-content .modalContainer .header .vidyard-player-container {
        width: 80% !important; }
      .modal-content .modalContainer .header iframe.vidyard_itrame {
        width: 95%;
        height: 51%;
        max-width: 100rem; }
      .modal-content .modalContainer .header p {
        color: #fff;
        padding-top: 1rem;
        text-align: left;
        font-size: 0.9rem; }
        .modal-content .modalContainer .header p a {
          color: #fff; }
      .modal-content .modalContainer .header span {
        color: #fff; }
    @media screen and (min-width: 1440px) {
      .modal-content .modalContainer .header .vidyard-player-container {
        width: 60% !important; } }
    @media screen and (min-width: 1900px) {
      .modal-content .modalContainer .header .vidyard-player-container {
        width: 50% !important; } }
    @media screen and (max-width: 1300px) {
      .modal-content .modalContainer .header iframe.vidyard_itrame {
        width: 96%;
        height: 82%; } }
    @media screen and (min-width: 641px) and (max-width: 900px) {
      .modal-content .modalContainer .header iframe.vidyard_itrame {
        width: 95%;
        height: 58%; } }
    @media screen and (max-width: 640px) {
      .modal-content .modalContainer .header iframe.vidyard_itrame {
        width: 95%;
        height: 36%; } }
  .modal-content .close {
    position: absolute;
    top: 0;
    right: 1%;
    color: #ffffff;
    float: right;
    font-size: 3rem;
    padding-right: 0rem;
    font-weight: 400; }
  .modal-content .close:hover,
  .modal-content .close:focus {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer; }

body > aside.four-col {
  background-color: #ffffff;
  text-align: center;
  color: #414141; }
  body > aside.four-col h3 {
    color: #cc0000;
    font-size: 1.5rem;
    margin-bottom: 6rem; }
  body > aside.four-col .flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    body > aside.four-col .flex article {
      -ms-flex: 0 0 23%;
          flex: 0 0 23%;
      box-shadow: 0px 0px 75px 0px rgba(0, 0, 0, 0.3);
      font-size: 0.85rem;
      padding-bottom: 2rem; }
      body > aside.four-col .flex article .image {
        padding-top: 2rem; }
        body > aside.four-col .flex article .image img {
          width: 7rem;
          box-shadow: 0 0 3.25rem rgba(0, 0, 0, 0.5); }
      body > aside.four-col .flex article .text {
        padding: 2rem 1.75rem 0; }
        body > aside.four-col .flex article .text h4 {
          color: #414141;
          font-size: .95rem;
          font-weight: bold; }
        body > aside.four-col .flex article .text p {
          padding-top: .2rem;
          color: #414141;
          font-size: .95rem;
          line-height: 1.6rem; }
      body > aside.four-col .flex article a {
        font-size: .95rem;
        color: #cc0000;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;
        margin-top: 1rem;
        display: block; }
    @media screen and (min-width: 641px) and (max-width: 900px) {
      body > aside.four-col .flex {
        display: block; } }
    @media screen and (max-width: 640px) {
      body > aside.four-col .flex {
        display: block; } }
  @media screen and (max-width: 640px) {
    body > aside.four-col {
      padding: 2.25rem 0.75rem; }
      body > aside.four-col .flex {
        -ms-flex-direction: column;
            flex-direction: column; }
        body > aside.four-col .flex article {
          margin-top: 1rem; }
          body > aside.four-col .flex article:first-of-type {
            margin-top: 0; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body > aside.four-col {
      padding: 2.25rem 2.5rem; }
      body > aside.four-col .flex {
        -ms-flex-direction: column;
            flex-direction: column; }
        body > aside.four-col .flex article {
          margin-top: 1rem; }
          body > aside.four-col .flex article:first-of-type {
            margin-top: 0; } }
  @media screen and (min-width: 901px) {
    body > aside.four-col {
      padding: 2.25rem calc(50vw - 610px) 10rem calc(50vw - 610px);
      padding-bottom: 3rem; }
      body > aside.four-col:after {
        content: " ";
        visibility: hidden;
        display: block;
        font-size: 0;
        clear: both;
        height: 0; } }

body > aside.two-col {
  background-color: #ffffff;
  text-align: left; }
  body > aside.two-col .flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    body > aside.two-col .flex article {
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
      font-size: 0.85rem;
      padding-bottom: 2rem; }
      body > aside.two-col .flex article .text {
        padding-top: 2rem; }
        body > aside.two-col .flex article .text h2 {
          color: #0C1938;
          font-size: 1.5rem;
          line-height: 2rem;
          font-weight: 300;
          padding-bottom: 1.5rem; }
        body > aside.two-col .flex article .text p {
          color: #000000;
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 300;
          padding-bottom: 2rem; }
      body > aside.two-col .flex article a {
        display: block;
        width: 220px;
        text-align: center;
        background: #cc0000;
        border: 1px solid #cc0000;
        padding: 1rem;
        font-size: .9rem;
        text-decoration: none;
        color: #fff;
        font-weight: 400; }
        body > aside.two-col .flex article a:hover {
          background: #fff;
          color: #cc0000;
          border: 1px solid #cc0000; }
    @media screen and (min-width: 641px) and (max-width: 900px) {
      body > aside.two-col .flex {
        display: block; } }
    @media screen and (max-width: 640px) {
      body > aside.two-col .flex {
        display: block; } }
  @media screen and (max-width: 640px) {
    body > aside.two-col {
      padding: 2rem 0.75rem; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body > aside.two-col {
      padding: 3rem 2.5rem; } }
  @media screen and (min-width: 901px) {
    body > aside.two-col {
      padding: 4rem calc(50vw - 610px);
      padding-bottom: 3rem; } }

section.section-1 .container .right img {
  width: 100%; }

body > aside.two-col-red {
  text-align: left; }
  body > aside.two-col-red .header {
    display: -ms-flexbox;
    display: flex; }
    body > aside.two-col-red .header .topLeft {
      background-color: #1186A8;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      height: 10rem;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center; }
      body > aside.two-col-red .header .topLeft h1 {
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: 1px;
        color: #ffffff; }
      body > aside.two-col-red .header .topLeft .fa-user-circle {
        color: #ffffff;
        font-size: 2rem;
        padding-right: 1rem; }
    body > aside.two-col-red .header .topRight {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      background: #1b1d1c; }
  body > aside.two-col-red .flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    body > aside.two-col-red .flex article {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      font-size: 0.85rem;
      padding-bottom: 2rem; }
      body > aside.two-col-red .flex article .text {
        color: #fff;
        padding-top: 2rem; }
        body > aside.two-col-red .flex article .text h1 {
          padding-top: 1rem;
          font-weight: 600;
          font-size: 2rem;
          line-height: 3rem;
          letter-spacing: 2px; }
        body > aside.two-col-red .flex article .text h2 {
          font-family: Courier Prime,monospace;
          font-size: 1.5rem;
          line-height: 2rem;
          font-weight: 300;
          padding-top: 1rem;
          padding-bottom: 1.5rem; }
        body > aside.two-col-red .flex article .text p {
          font-size: 1rem;
          font-weight: normal;
          line-height: 2rem;
          padding-bottom: 2rem; }
        body > aside.two-col-red .flex article .text a {
          text-decoration: none;
          border-bottom: 2px solid #fff;
          padding-bottom: 5px;
          font-size: 1rem;
          font-weight: bold;
          color: #ffffff; }
      body > aside.two-col-red .flex article.left {
        background-color: #00b3e8; }
        body > aside.two-col-red .flex article.left img {
          width: 10rem;
          height: auto; }
      body > aside.two-col-red .flex article.right {
        background-color: #e2e36a; }
        body > aside.two-col-red .flex article.right img {
          width: 10rem;
          height: auto; }
        body > aside.two-col-red .flex article.right h1 {
          color: #000000; }
        body > aside.two-col-red .flex article.right h2 {
          color: #00b3e8; }
        body > aside.two-col-red .flex article.right p {
          color: #000000; }
        body > aside.two-col-red .flex article.right a {
          color: #00b3e8;
          border-bottom: 2px solid #00b3e8; }
    @media screen and (min-width: 641px) and (max-width: 900px) {
      body > aside.two-col-red .flex {
        display: block; }
        body > aside.two-col-red .flex article .text {
          padding-top: 0; } }
    @media screen and (max-width: 640px) {
      body > aside.two-col-red .flex {
        display: block; }
        body > aside.two-col-red .flex article .text {
          padding-top: 0;
          padding-right: 0; }
          body > aside.two-col-red .flex article .text p {
            padding-right: 0; } }
    @media screen and (min-width: 901px) {
      body > aside.two-col-red .flex article .text {
        padding-top: 0; } }
  @media screen and (max-width: 640px) {
    body > aside.two-col-red .header .topLeft, body > aside.two-col-red .header .topRight {
      padding: 5rem 0.75rem; }
    body > aside.two-col-red .header .topLeft {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%; }
    body > aside.two-col-red .header .topRight {
      display: none; }
    body > aside.two-col-red .flex .left, body > aside.two-col-red .flex .right {
      padding: 5rem 2.5rem; }
      body > aside.two-col-red .flex .left .text h1, body > aside.two-col-red .flex .right .text h1 {
        font-size: 1.5rem; }
      body > aside.two-col-red .flex .left .text h2, body > aside.two-col-red .flex .right .text h2 {
        padding-top: 0;
        padding-bottom: .5rem;
        font-size: 1rem; }
      body > aside.two-col-red .flex .left .text p, body > aside.two-col-red .flex .right .text p {
        font-size: .9rem; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body > aside.two-col-red .header .topLeft, body > aside.two-col-red .header .topRight {
      padding: 5rem 2.5rem; }
    body > aside.two-col-red .flex .left, body > aside.two-col-red .flex .right {
      padding: 5rem 2.5rem; } }
  @media screen and (min-width: 901px) {
    body > aside.two-col-red .header .topLeft, body > aside.two-col-red .header .topRight {
      padding: 6rem calc(50vw - 610px); }
    body > aside.two-col-red .flex .left, body > aside.two-col-red .flex .right {
      padding: 7rem calc(50vw - 610px); } }
  @media screen and (min-width: 1400px) {
    body > aside.two-col-red .header .topLeft, body > aside.two-col-red .header .topRight {
      padding: 6rem 10rem; }
    body > aside.two-col-red .flex .left, body > aside.two-col-red .flex .right {
      padding: 7rem 10rem; } }

body > aside.blog {
  background-color: #F3F3F3;
  text-align: center;
  color: #414141; }
  body > aside.blog .container {
    display: -ms-flexbox;
    display: flex; }
    body > aside.blog .container article {
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
      font-size: 0.95rem;
      width: 80%;
      margin: 0 1rem;
      text-align: left; }
      body > aside.blog .container article .mktoText {
        padding-right: 2rem; }
        body > aside.blog .container article .mktoText h1 {
          color: #cc0000;
          font-weight: 400;
          font-size: 2rem; }
        body > aside.blog .container article .mktoText h3 {
          font-weight: 300;
          font-size: 1.3rem;
          padding-top: 1rem; }
          body > aside.blog .container article .mktoText h3 span {
            font-weight: 400; }
        body > aside.blog .container article .mktoText p {
          padding-top: 1.5rem;
          font-size: .9rem; }
      body > aside.blog .container article.formWrap {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
        background-color: #ababab;
        padding: 0rem; }
        body > aside.blog .container article.formWrap .top {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
          body > aside.blog .container article.formWrap .top .mktoText {
            font-weight: bold;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
                align-items: center;
            -ms-flex: 0 0 45%;
                flex: 0 0 45%;
            line-height: 1.4rem; }
          body > aside.blog .container article.formWrap .top .mktoImg {
            -ms-flex: 0 0 45%;
                flex: 0 0 45%;
            text-align: center; }
            body > aside.blog .container article.formWrap .top .mktoImg img {
              width: 8rem; }
        body > aside.blog .container article.formWrap .mktoForm {
          padding: 2.5rem;
          background: white; }
          body > aside.blog .container article.formWrap .mktoForm form {
            padding: 0; }
          body > aside.blog .container article.formWrap .mktoForm p {
            font-style: normal;
            font-weight: 700;
            font-size: .75rem;
            color: #464646;
            line-height: 1.9;
            width: 100%;
            margin-bottom: 1.375rem; }
          body > aside.blog .container article.formWrap .mktoForm input[type=text], body > aside.blog .container article.formWrap .mktoForm input[type=email], body > aside.blog .container article.formWrap .mktoForm input[type=tel], body > aside.blog .container article.formWrap .mktoForm select, body > aside.blog .container article.formWrap .mktoForm textarea {
            display: block;
            width: 100% !important;
            padding: 1rem .5rem;
            font-family: din-2014, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: .8rem;
            background-color: #f7f7f7;
            border: .1em solid #b9b8b2;
            border-radius: 0.2rem; }
          body > aside.blog .container article.formWrap .mktoForm select {
            -webkit-appearance: none;
                    appearance: none;
            background: #f7f7f7 url(".http://na-ab19.marketo.com/rs/444-TCD-959/images/triangle.png");
            background-position-x: 95%;
            background-position-y: 50%;
            background-size: .6rem;
            color: #757575;
            background-repeat: no-repeat; }
          body > aside.blog .container article.formWrap .mktoForm input[type=radio] {
            -webkit-appearance: radio;
                    appearance: radio;
            position: relative;
            margin: 5px; }
            body > aside.blog .container article.formWrap .mktoForm input[type=radio]::before {
              position: absolute;
              height: 20px;
              width: 20px;
              top: -0px;
              left: -0px;
              border: 1px solid grey;
              content: '';
              background: white;
              border-radius: 0.2rem; }
            body > aside.blog .container article.formWrap .mktoForm input[type=radio]::after {
              position: absolute;
              display: none;
              height: 20px;
              width: 20px;
              top: 0px;
              left: 0px;
              content: '';
              background: #CC0000;
              border-radius: 0.2rem; }
            body > aside.blog .container article.formWrap .mktoForm input[type=radio]:checked:after {
              display: block; }
          body > aside.blog .container article.formWrap .mktoForm .mktoRadioList label {
            display: block; }
          body > aside.blog .container article.formWrap .mktoForm input[type=checkbox] {
            -webkit-appearance: checkbox;
                    appearance: checkbox; }
          body > aside.blog .container article.formWrap .mktoForm button {
            font-family: din-2014, sans-serif;
            text-transform: uppercase;
            width: 60%;
            font-weight: 600;
            font-size: .9rem;
            background: #CC0000;
            padding: 1rem 2rem;
            border: 1px solid #CC0000;
            color: #fff;
            float: left;
            margin-top: -2rem; }
        body > aside.blog .container article.formWrap form .mktoForm {
          padding: 0; }
        body > aside.blog .container article.formWrap form .mktoFormRow, body > aside.blog .container article.formWrap form .mktoButtonRow {
          width: 100% !important;
          padding-bottom: 1rem; }
        body > aside.blog .container article.formWrap form .mktoButtonRow {
          margin-top: 2.5rem; }
        body > aside.blog .container article.formWrap form label {
          display: none; }
        body > aside.blog .container article.formWrap form .mktoClear {
          display: none; }
        body > aside.blog .container article.formWrap form .mktoOffset {
          display: none; }
        body > aside.blog .container article.formWrap form .mktoGutter {
          display: none; }
        body > aside.blog .container article.formWrap form.mktoForm {
          width: 100% !important; }
          body > aside.blog .container article.formWrap form.mktoForm .mktoFormRow {
            width: 100% !important;
            display: -ms-flexbox;
            display: flex; }
            body > aside.blog .container article.formWrap form.mktoForm .mktoFormRow .mktoRadioList {
              display: -ms-flexbox;
              display: flex;
              -ms-flex-align: center;
                  align-items: center; }
              body > aside.blog .container article.formWrap form.mktoForm .mktoFormRow .mktoRadioList > label {
                padding-top: .5rem;
                margin: 0 1.4rem; }
          body > aside.blog .container article.formWrap form.mktoForm .mktoLabel {
            width: 100% !important; }
          body > aside.blog .container article.formWrap form.mktoForm .mktoFormCol {
            width: 100%; }
          body > aside.blog .container article.formWrap form.mktoForm .mktoFieldWrap {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
                flex-direction: column;
            width: 100%; }
          body > aside.blog .container article.formWrap form.mktoForm .mktoRequiredField label.mktoLabel {
            width: 7rem !important;
            font-weight: normal;
            font-size: .8rem; }
            body > aside.blog .container article.formWrap form.mktoForm .mktoRequiredField label.mktoLabel#LblglobalOptinConsentProxyField {
              width: 100% !important;
              display: -ms-flexbox;
              display: flex; }
              body > aside.blog .container article.formWrap form.mktoForm .mktoRequiredField label.mktoLabel#LblglobalOptinConsentProxyField .mktoAsterix {
                -ms-flex-order: 1;
                    order: 1;
                float: none;
                display: inline-block; }
      body > aside.blog .container article form {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        padding-top: 1rem; }
        body > aside.blog .container article form p {
          font-style: normal;
          font-weight: 700;
          font-size: .75rem;
          color: #48565E;
          line-height: 1.9;
          width: 50%;
          margin-bottom: 1.375rem;
          padding-right: 1rem; }
          body > aside.blog .container article form p.checkbox {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
                align-items: center;
            margin-bottom: 0; }
            body > aside.blog .container article form p.checkbox input[type=checkbox] {
              -webkit-appearance: checkbox;
                      appearance: checkbox; }
            body > aside.blog .container article form p.checkbox label {
              padding-left: .5rem; }
          body > aside.blog .container article form p.phone, body > aside.blog .container article form p.checkbox, body > aside.blog .container article form p:last-of-type {
            width: 100%; }
          body > aside.blog .container article form p:last-of-type {
            padding-left: 1.3rem; }
          body > aside.blog .container article form p input[type=text], body > aside.blog .container article form p input[type=email], body > aside.blog .container article form p input[type=tel] {
            width: 100%;
            height: 2.375rem;
            padding: 0 .5rem;
            font-family: Roboto,sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: .8rem;
            background-color: #fff;
            border-radius: 0; }
          body > aside.blog .container article form p #form-country {
            background-image: url(".http://na-ab19.marketo.com/rs/444-TCD-959/images/triangle.png");
            background-repeat: no-repeat;
            background-position: 96% 50%; }
        body > aside.blog .container article form button {
          display: block;
          width: 220px;
          text-align: center;
          background: #cc0000;
          border: 1px solid #cc0000;
          padding: 1rem;
          font-size: .9rem;
          text-decoration: none;
          color: #fff;
          font-weight: 400; }
          body > aside.blog .container article form button:hover {
            background: #fff;
            color: #cc0000;
            border: 1px solid #cc0000; }
  @media screen and (max-width: 640px) {
    body > aside.blog {
      padding: 2rem 0.75rem; }
      body > aside.blog .container {
        display: block; }
        body > aside.blog .container article.copy {
          width: 100%;
          margin: 0;
          margin-left: 2rem; }
          body > aside.blog .container article.copy .mktoText {
            padding-right: 4rem; }
        body > aside.blog .container article.formWrap {
          margin: 2rem auto;
          padding: 10rem; }
          body > aside.blog .container article.formWrap form {
            display: block; }
            body > aside.blog .container article.formWrap form p {
              width: 100%; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body > aside.blog {
      padding: 3rem 2.5rem; }
      body > aside.blog .container {
        display: block; }
        body > aside.blog .container article.copy {
          width: 100%; }
        body > aside.blog .container article.formWrap {
          margin: 2rem auto;
          width: 100%;
          padding: 10rem; } }
  @media screen and (min-width: 901px) {
    body > aside.blog {
      padding: 4rem calc(50vw - 610px); } }

body > section.ctaButton {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center; }
  body > section.ctaButton #bodyCTACopy, body > section.ctaButton #bottomCTACopy {
    line-height: 1.6rem;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    width: 40%;
    padding: .5rem 0;
    color: #cc0000; }
  @media screen and (max-width: 640px) {
    body > section.ctaButton {
      padding: 0 0.75rem 1rem; }
      body > section.ctaButton #bodyCTACopy, body > section.ctaButton #bottomCTACopy {
        width: 100%; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body > section.ctaButton {
      padding: 0 2.5rem 1rem; } }
  @media screen and (min-width: 901px) {
    body > section.ctaButton {
      padding: 0 calc(50vw - 610px) 2rem; } }

body > section.copy_strip {
  background: #1b1d1c;
  background-image: url("http://na-ab19.marketo.com/rs/444-TCD-959/images/black01BG.jpg");
  background-size: initial;
  background-repeat: no-repeat;
  background-position: right top;
  text-align: left;
  color: #ffffff; }
  body > section.copy_strip .wrap {
    position: relative; }
    body > section.copy_strip .wrap .copyStripContent h3 {
      font-family: 'Courier New', Courier, monospace;
      color: #e2e367;
      font-size: 1.4rem; }
    body > section.copy_strip .wrap .copyStripContent h1 {
      padding-top: 1.5rem;
      font-size: 3rem;
      font-weight: 600;
      line-height: 3.7rem;
      letter-spacing: 1px;
      width: 55%; }
    body > section.copy_strip .wrap .copyStripContent p {
      padding-top: 1.5rem;
      width: 67%;
      font-size: 1rem;
      line-height: 1.8rem;
      font-weight: normal;
      padding-bottom: 2.5rem; }
      body > section.copy_strip .wrap .copyStripContent p.numbers {
        font-size: 1.4rem;
        line-height: 2.25rem;
        padding-top: 0.5rem; }
      body > section.copy_strip .wrap .copyStripContent p.legal a {
        background: transparent;
        display: inline;
        text-transform: none;
        padding: 0;
        font-weight: 400;
        font-size: 1rem;
        text-decoration: underline;
        letter-spacing: 0;
        color: #00b3e8; }
        body > section.copy_strip .wrap .copyStripContent p.legal a:hover {
          color: #666; }
    body > section.copy_strip .wrap .copyStripContent a {
      font-family: 'Adobe Clean Bold';
      font-size: 13px;
      font-size: 14px;
      border-radius: 2rem;
      padding: 0.5rem 1rem 0.65rem;
      color: #ffffff;
      display: block;
      text-align: center;
      background: #1473E6;
      /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#3688eb",GradientType=1);*/
      border: 1px solid #1473E6;
      text-transform: uppercase;
      text-decoration: none;
      transition: all 0.3s ease;
      width: 200px;
      transition: all 0.3s ease; }
      body > section.copy_strip .wrap .copyStripContent a:hover {
        transition: all 0.3s ease;
        background: #0d66d0;
        border: 1px solid #0d66d0;
        /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#1373e4",GradientType=1);*/ }
      body > section.copy_strip .wrap .copyStripContent a:hover {
        transition: all 0.3s ease; }
  body > section.copy_strip .footerWrap .footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    body > section.copy_strip .footerWrap .footer .left img {
      width: 10rem; }
    body > section.copy_strip .footerWrap .footer .right p {
      font-size: .9rem; }
    body > section.copy_strip .footerWrap .footer .right ul {
      list-style-type: none;
      display: -ms-flexbox;
      display: flex; }
      body > section.copy_strip .footerWrap .footer .right ul li a {
        position: relative;
        display: block;
        margin: 0 5px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        transition: background 350ms;
        color: #00b3e6; }
        body > section.copy_strip .footerWrap .footer .right ul li a svg {
          position: absolute;
          top: 50%;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 100%;
          min-width: 18px;
          max-width: 18px;
          max-height: 18px;
          fill: #fff;
          transition: fill 350ms; }
          @media screen and (max-width: 640px) {
            body > section.copy_strip .footerWrap .footer .right ul li a svg {
              max-width: 28px;
              max-height: 28px; } }
        body > section.copy_strip .footerWrap .footer .right ul li a:hover {
          background: #00b3e6; }
          body > section.copy_strip .footerWrap .footer .right ul li a:hover svg {
            fill: #000; }
        @media screen and (max-width: 640px) {
          body > section.copy_strip .footerWrap .footer .right ul li a {
            width: 40px;
            height: 40px; } }
      @media screen and (max-width: 640px) {
        body > section.copy_strip .footerWrap .footer .right ul {
          -ms-flex-pack: center;
              justify-content: center; } }
    body > section.copy_strip .footerWrap .footer .fullWidth {
      padding-top: 3rem;
      width: 100%;
      font-size: 0.9rem; }
  @media screen and (max-width: 640px) {
    body > section.copy_strip {
      padding: 5rem 2.5rem; }
      body > section.copy_strip .wrap .copyStripContent h1 {
        width: 100%; }
      body > section.copy_strip .wrap .copyStripContent p {
        width: 100%; }
      body > section.copy_strip .footerWrap {
        padding-top: 5rem; }
        body > section.copy_strip .footerWrap .footer {
          display: block; }
          body > section.copy_strip .footerWrap .footer .left {
            text-align: center; }
          body > section.copy_strip .footerWrap .footer .right {
            text-align: center;
            padding-top: 2rem; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body > section.copy_strip {
      padding: 5rem 2.5rem; }
      body > section.copy_strip .wrap .copyStripContent h1 {
        width: 100%; }
      body > section.copy_strip .wrap .copyStripContent p {
        width: 100%; }
      body > section.copy_strip .footerWrap {
        padding-top: 5rem; }
        body > section.copy_strip .footerWrap .footer {
          display: block; }
          body > section.copy_strip .footerWrap .footer .right {
            padding-top: 2rem; } }
  @media screen and (min-width: 901px) {
    body > section.copy_strip .wrap {
      padding: 8rem calc(50vw - 610px); }
    body > section.copy_strip .footerWrap {
      padding: 2rem calc(50vw - 610px); } }

body > section.copy_strip_centered {
  text-align: center; }
  body > section.copy_strip_centered h1 {
    color: #cc0000;
    font-size: 2.2rem;
    line-height: 2.7rem;
    font-weight: 400; }
  body > section.copy_strip_centered h2 {
    color: #0C1938;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 2rem;
    padding-top: 1rem; }
  @media screen and (max-width: 640px) {
    body > section.copy_strip_centered {
      padding: 2rem 0.75rem; }
      body > section.copy_strip_centered #bodyCTACopy, body > section.copy_strip_centered #bottomCTACopy {
        width: 100%;
        text-align: center;
        padding: 0; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body > section.copy_strip_centered {
      padding: 3rem 2.5rem; } }
  @media screen and (min-width: 901px) {
    body > section.copy_strip_centered {
      padding: 4rem calc(50vw - 610px); } }

body > section.copy_strip_red {
  background-image: url("https://pages.hitachivantara.com/rs/375-JOE-551/images/red-bg.png");
  background-size: cover;
  text-align: center; }
  body > section.copy_strip_red .mktoText {
    color: #fff;
    padding: 0 2rem; }
    body > section.copy_strip_red .mktoText h1 {
      font-size: 2rem;
      font-weight: 300; }
    body > section.copy_strip_red .mktoText p {
      font-weight: 300;
      font-size: 1.2rem;
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 4rem;
      padding-left: 5rem;
      padding-right: 5rem; }
    body > section.copy_strip_red .mktoText a {
      font-family: 'Fira Sans', sans-serif;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 2px;
      display: block;
      width: 220px;
      text-align: center;
      background: #fff;
      padding: 1rem;
      text-decoration: none;
      color: #000000;
      margin: 0 auto; }
      body > section.copy_strip_red .mktoText a:hover {
        background: #00b3e8;
        color: #fff; }
  @media screen and (max-width: 640px) {
    body > section.copy_strip_red {
      padding: 2rem 0.75rem; }
      body > section.copy_strip_red .mktoText {
        padding: 0; }
        body > section.copy_strip_red .mktoText p {
          padding: 2rem 0 2rem; }
        body > section.copy_strip_red .mktoText a {
          margin: 0 auto; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body > section.copy_strip_red {
      padding: 3rem 2.5rem; } }
  @media screen and (min-width: 901px) {
    body > section.copy_strip_red {
      padding: 4rem calc(50vw - 610px); } }

section.copy_strip_header {
  text-align: center;
  background-color: #f7f7f7; }
  section.copy_strip_header #headlineHeading h3 {
    font-family: Courier Prime,monospace;
    color: #00b3e8;
    font-size: 1.7rem;
    font-weight: 400; }
  section.copy_strip_header #headlineHeading h1 {
    color: #383838;
    font-size: 2.5rem;
    font-weight: 700; }
  section.copy_strip_header #headlineCopy {
    padding-top: 2rem; }
    section.copy_strip_header #headlineCopy p {
      color: #000;
      line-height: 1.5rem;
      font-size: 1.1rem;
      font-weight: normal;
      padding: 0 8rem 2rem; }
    section.copy_strip_header #headlineCopy a {
      margin: 0 auto;
      font-family: 'Adobe Clean Bold';
      font-size: 13px;
      font-size: 14px;
      border-radius: 2rem;
      padding: 0.5rem 1rem 0.65rem;
      color: #ffffff;
      display: block;
      text-align: center;
      background: #1473E6;
      /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#3688eb",GradientType=1);*/
      border: 1px solid #1473E6;
      text-transform: uppercase;
      text-decoration: none;
      transition: all 0.3s ease;
      width: 200px;
      background-color: #e2e36a;
      color: black;
      transition: all 0.3s ease; }
      section.copy_strip_header #headlineCopy a:hover {
        transition: all 0.3s ease;
        background: #0d66d0;
        border: 1px solid #0d66d0;
        /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#1373e4",GradientType=1);*/ }
      section.copy_strip_header #headlineCopy a:hover {
        background-color: #00b3e6;
        transition: all 0.3s ease;
        color: white; }
  @media screen and (max-width: 640px) {
    section.copy_strip_header {
      padding: 5rem 0.75rem; }
      section.copy_strip_header #headlineHeading h3 {
        font-size: 1.5rem; }
      section.copy_strip_header #headlineHeading h1 {
        font-size: 2rem; }
      section.copy_strip_header #headlineCopy {
        padding-top: 0; }
        section.copy_strip_header #headlineCopy p {
          padding: 1rem 0 2rem;
          font-size: .9rem; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    section.copy_strip_header {
      padding: 5rem 2.5rem; }
      section.copy_strip_header #headlineCopy {
        padding-top: 0; }
        section.copy_strip_header #headlineCopy p {
          padding: 1rem 4rem 2rem; } }
  @media screen and (min-width: 901px) {
    section.copy_strip_header {
      padding: 7rem calc(50vw - 610px); } }

body > section.vidBlock1 {
  display: -ms-flexbox;
  display: flex; }
  body > section.vidBlock1 .left {
    -ms-flex: 0 0 45%;
        flex: 0 0 45%;
    padding-right: 2rem;
    margin-right: 2rem;
    padding-left: 1rem; }
    body > section.vidBlock1 .left #vidBlock1Copy h2, body > section.vidBlock1 .left #vidBlock2Copy h2 {
      font-size: 2rem;
      font-weight: 600;
      padding-bottom: 1rem; }
    body > section.vidBlock1 .left #vidBlock1Copy p, body > section.vidBlock1 .left #vidBlock2Copy p {
      color: #414141;
      font-size: .95rem;
      padding-bottom: 1rem;
      line-height: 1.9rem; }
    body > section.vidBlock1 .left a {
      text-decoration: none;
      color: #cc0000;
      font-weight: bold; }
  body > section.vidBlock1 .right {
    -ms-flex: 0 0 45%;
        flex: 0 0 45%; }
    body > section.vidBlock1 .right #VidBlock1img, body > section.vidBlock1 .right #VidBlock2img {
      text-align: center; }
    body > section.vidBlock1 .right img {
      width: 35rem; }
  @media screen and (max-width: 640px) {
    body > section.vidBlock1 {
      padding: 2rem 0.75rem;
      -ms-flex-direction: column;
          flex-direction: column; }
      body > section.vidBlock1 .right img {
        padding-top: 1rem;
        width: 100%; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body > section.vidBlock1 {
      padding: 2rem 2.5rem;
      -ms-flex-direction: column;
          flex-direction: column; }
      body > section.vidBlock1 .right img {
        padding-top: 1rem;
        width: 100%; } }
  @media screen and (min-width: 901px) {
    body > section.vidBlock1 {
      padding: 1rem calc(50vw - 610px) 5rem; } }

section.imageText .wrapper {
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  background: white;
  -ms-flex-align: center;
      align-items: center; }
  section.imageText .wrapper .left {
    -ms-flex: 0 0 45%;
        flex: 0 0 45%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center; }
    section.imageText .wrapper .left .mktoImg {
      width: 100%; }
      section.imageText .wrapper .left .mktoImg img {
        width: 100%;
        height: 100%;
        display: block; }
  section.imageText .wrapper .right {
    -ms-flex: 0 0 55%;
        flex: 0 0 55%;
    padding: 2.25rem 5rem; }
    section.imageText .wrapper .right .mktoText {
      padding-top: 2rem;
      padding-bottom: 2rem;
      text-align: left; }
      section.imageText .wrapper .right .mktoText h2 {
        color: #0C1938;
        font-weight: 300;
        padding-bottom: 1.5rem;
        font-size: 1.5rem;
        line-height: 2rem; }
      section.imageText .wrapper .right .mktoText p {
        color: #000000;
        font-size: 1rem;
        line-height: 1.7rem;
        padding-bottom: 2rem; }
      section.imageText .wrapper .right .mktoText a {
        background: transparent;
        padding: 0;
        margin: 0;
        width: auto;
        border: 0; }
    section.imageText .wrapper .right a.redButton {
      display: block;
      width: 220px;
      text-align: center;
      background: #cc0000;
      border: 1px solid #cc0000;
      padding: 1rem;
      font-size: .9rem;
      text-decoration: none;
      color: #fff;
      font-weight: 400; }
      section.imageText .wrapper .right a.redButton:hover {
        background: #fff;
        color: #cc0000;
        border: 1px solid #cc0000; }

@media screen and (max-width: 640px) {
  section.imageText {
    padding: 2rem 0.75rem; }
    section.imageText .wrapper {
      display: block;
      margin: 0 auto; }
      section.imageText .wrapper .left .mktoImg {
        width: 100%; }
      section.imageText .wrapper .right {
        padding: 0 2rem 2rem; } }

@media screen and (min-width: 641px) and (max-width: 900px) {
  section.imageText {
    padding: 3rem 2.5rem; }
    section.imageText .wrapper {
      display: block;
      width: 70%;
      margin: 0 auto; }
      section.imageText .wrapper .left .mktoImg {
        width: 100%; }
      section.imageText .wrapper .right {
        padding: 0 2rem 2rem; } }

@media screen and (min-width: 901px) {
  section.imageText {
    padding: 4rem calc(50vw - 610px); } }

section.textImage .wrapper {
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  background: white;
  -ms-flex-align: center;
      align-items: center; }
  section.textImage .wrapper .left {
    -ms-flex: 0 0 55%;
        flex: 0 0 55%;
    padding: 2.25rem 5rem; }
    section.textImage .wrapper .left .mktoText {
      width: 100%;
      padding-top: 2rem;
      padding-bottom: 2rem;
      text-align: left; }
      section.textImage .wrapper .left .mktoText h2 {
        color: #0C1938;
        font-weight: 300;
        padding-bottom: 1.5rem;
        font-size: 1.5rem;
        line-height: 2rem; }
      section.textImage .wrapper .left .mktoText p {
        color: #000000;
        font-size: 1rem;
        line-height: 1.7rem;
        padding-bottom: 2rem; }
      section.textImage .wrapper .left .mktoText a {
        background: transparent;
        padding: 0;
        margin: 0;
        width: auto;
        border: 0; }
    section.textImage .wrapper .left a.redButton {
      display: block;
      width: 220px;
      text-align: center;
      background: #cc0000;
      border: 1px solid #cc0000;
      padding: 1rem;
      font-size: .9rem;
      text-decoration: none;
      color: #fff;
      font-weight: 400; }
      section.textImage .wrapper .left a.redButton:hover {
        background: #fff;
        color: #cc0000;
        border: 1px solid #cc0000; }
  section.textImage .wrapper .right {
    -ms-flex: 0 0 45%;
        flex: 0 0 45%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center; }
    section.textImage .wrapper .right .mktoImg {
      width: 100%; }
      section.textImage .wrapper .right .mktoImg img {
        width: 100%;
        height: 100%;
        display: block; }

@media screen and (max-width: 640px) {
  section.textImage {
    padding: 2rem 0.75rem; }
    section.textImage .wrapper {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      margin: 0 auto; }
      section.textImage .wrapper .left {
        -ms-flex-order: 2;
            order: 2;
        padding: 0 2rem 2rem; }
      section.textImage .wrapper .right {
        -ms-flex-order: 1;
            order: 1; }
        section.textImage .wrapper .right .mktoImg {
          width: 100%; } }

@media screen and (min-width: 641px) and (max-width: 900px) {
  section.textImage {
    padding: 3rem 2.5rem; }
    section.textImage .wrapper {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      width: 70%;
      margin: 0 auto; }
      section.textImage .wrapper .left {
        -ms-flex-order: 2;
            order: 2;
        padding: 0 2rem 2rem; }
      section.textImage .wrapper .right {
        -ms-flex-order: 1;
            order: 1; }
        section.textImage .wrapper .right .mktoImg {
          width: 100%; } }

@media screen and (min-width: 901px) {
  section.textImage {
    padding: 4rem calc(50vw - 610px) 3rem; } }

body > section.contactDetails {
  background: #1b8b8c;
  color: white;
  text-align: center; }
  body > section.contactDetails h3 {
    padding-bottom: 1rem; }
  body > section.contactDetails #contactDetailsCopy {
    padding: 0 7rem;
    line-height: 1.7rem; }
    body > section.contactDetails #contactDetailsCopy a {
      color: #fff; }
  body > section.contactDetails img {
    padding-top: 1.5rem;
    width: 10%; }
  body > section.contactDetails #contactDetails {
    padding-top: .75rem;
    font-weight: bold;
    line-height: 1.7rem; }
  @media screen and (max-width: 640px) {
    body > section.contactDetails {
      padding: 3rem 0.75rem; }
      body > section.contactDetails #contactDetailsCopy {
        padding: 0; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body > section.contactDetails {
      padding: 2rem 2.5rem; }
      body > section.contactDetails #contactDetailsCopy {
        padding: 0; } }
  @media screen and (min-width: 901px) {
    body > section.contactDetails {
      padding: 5rem calc(50vw - 610px); } }

.copy_icon_button {
  background-image: url("http://na-ab19.marketo.com/rs/444-TCD-959/images/black01BG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-attachment: fixed; }
  .copy_icon_button.compliance .wrapper .flex:first-of-type .gartnerImg img {
    border: 0; }
  .copy_icon_button.compliance .wrapper .flex:last-of-type .gartnerImg img {
    border: 0; }
  .copy_icon_button.retail .wrapper .flex ul {
    list-style-position: inside; }
  .copy_icon_button.retail .wrapper .flex .gartnerImg img {
    max-width: 350px; }
  .copy_icon_button.blue {
    background-image: url("../images/blue-bg.jpg"); }
    .copy_icon_button.blue .wrapper .flex .icon {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: end;
          justify-content: flex-end;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%; }
      .copy_icon_button.blue .wrapper .flex .icon .assetImg {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        position: relative; }
        .copy_icon_button.blue .wrapper .flex .icon .assetImg img {
          width: 100%;
          border: 3px solid white; }
        .copy_icon_button.blue .wrapper .flex .icon .assetImg .play {
          position: absolute;
          top: 50%;
          left: 50%;
          -ms-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
          border: 0;
          width: 30%; }
    .copy_icon_button.blue .wrapper .flex a {
      background: black;
      transition: all 0.3s ease;
      width: 200px; }
      .copy_icon_button.blue .wrapper .flex a:hover {
        background: white;
        color: black;
        transition: all 0.3s ease; }
    .copy_icon_button.blue .wrapper .flex h3 {
      color: black; }
    .copy_icon_button.blue .wrapper .flex .gartnerCopy h3 {
      color: black; }
    .copy_icon_button.blue .wrapper .flex .gartnerImg img {
      width: 80%; }
    .copy_icon_button.blue .wrapper .flex .ebookDownload .cta {
      background: white;
      transition: all 0.3s ease; }
      .copy_icon_button.blue .wrapper .flex .ebookDownload .cta:hover {
        transition: all 0.3s ease;
        background: black;
        color: white; }
    .copy_icon_button.blue.cost .wrapper .flex .gartnerImg img {
      max-width: 250px; }
    .copy_icon_button.blue.cost .wrapper .flex .icon .assetImg img {
      max-width: 250px; }
    .copy_icon_button.blue.compliance .wrapper .flex .gartnerImg img {
      max-width: 250px;
      border: 3px solid white; }
    .copy_icon_button.blue.compliance .wrapper .flex .icon .assetImg img {
      max-width: 250px;
      border: 0; }
    .copy_icon_button.blue.compliance.retail .flex .gartnerImg img {
      max-width: 350px;
      border: 3px solid white; }
  .copy_icon_button h2 {
    text-align: center;
    color: #ffffff;
    font-size: 3.4rem;
    padding-bottom: 9rem; }
    @media screen and (max-width: 640px) {
      .copy_icon_button h2 {
        font-size: 2rem;
        padding-bottom: 3rem;
        text-align: left; } }
  .copy_icon_button .wrapper {
    padding-left: 4rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    text-align: left;
    color: #ffffff; }
    .copy_icon_button .wrapper .flex {
      width: 100%;
      display: -ms-flexbox;
      display: flex; }
      .copy_icon_button .wrapper .flex:nth-of-type(2) {
        padding-top: 9rem; }
        @media screen and (max-width: 640px) {
          .copy_icon_button .wrapper .flex:nth-of-type(2) {
            padding-top: 4rem; }
            .copy_icon_button .wrapper .flex:nth-of-type(2) article .icon {
              -ms-flex-order: 1;
                  order: 1; } }
      .copy_icon_button .wrapper .flex:nth-of-type(3) {
        padding-top: 9rem; }
        @media screen and (max-width: 640px) {
          .copy_icon_button .wrapper .flex:nth-of-type(3) {
            padding-top: 4rem; } }
      .copy_icon_button .wrapper .flex h3 {
        font-family: Courier Prime,monospace;
        color: #00b3e8;
        font-size: 1.4rem; }
      .copy_icon_button .wrapper .flex h1 {
        padding-top: 1.5rem;
        font-size: 1.7rem;
        font-weight: 600; }
      .copy_icon_button .wrapper .flex p {
        padding-top: 1.5rem;
        font-size: 1.1rem;
        line-height: 1.9rem;
        color: #ffffff;
        font-weight: normal;
        padding-right: 3rem;
        padding-bottom: 2rem; }
      .copy_icon_button .wrapper .flex a {
        font-family: 'Adobe Clean Bold';
        font-size: 13px;
        font-size: 14px;
        border-radius: 2rem;
        padding: 0.5rem 1rem 0.65rem;
        color: #ffffff;
        display: block;
        text-align: center;
        background: #1473E6;
        /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#3688eb",GradientType=1);*/
        border: 1px solid #1473E6;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.3s ease;
        width: 190px; }
        .copy_icon_button .wrapper .flex a:hover {
          transition: all 0.3s ease;
          background: #0d66d0;
          border: 1px solid #0d66d0;
          /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#1373e4",GradientType=1);*/ }
      .copy_icon_button .wrapper .flex .gartnerImg {
        -ms-flex: 0 0 40%;
            flex: 0 0 40%; }
        .copy_icon_button .wrapper .flex .gartnerImg img {
          width: 16rem;
          border: 3px solid white; }
      .copy_icon_button .wrapper .flex .ebookDownload .cta {
        background: #e2e367;
        transition: all 0.3s ease; }
        .copy_icon_button .wrapper .flex .ebookDownload .cta:hover {
          background: #00b3e8; }
      .copy_icon_button .wrapper .flex .gartnerCopy {
        -ms-flex: 0 0 60%;
            flex: 0 0 60%; }
        .copy_icon_button .wrapper .flex .gartnerCopy h3 {
          color: #e2e367; }
        @media screen and (max-width: 640px) {
          .copy_icon_button .wrapper .flex .gartnerCopy {
            padding-top: 2rem; } }
      .copy_icon_button .wrapper .flex .copy {
        -ms-flex: 0 0 70%;
            flex: 0 0 70%;
        padding-right: 9rem; }
        .copy_icon_button .wrapper .flex .copy .text {
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; }
        .copy_icon_button .wrapper .flex .copy a {
          font-family: 'Fira Sans', sans-serif;
          font-size: 13px;
          font-weight: 700;
          letter-spacing: 2px;
          display: block;
          width: 220px;
          text-align: center;
          background: #fff;
          padding: 1rem;
          text-decoration: none;
          color: #000000; }
          .copy_icon_button .wrapper .flex .copy a:hover {
            background: #00b3e8;
            color: #fff; }
      .copy_icon_button .wrapper .flex .icon {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: end;
            justify-content: flex-end;
        -ms-flex: 0 0 30%;
            flex: 0 0 30%; }
        .copy_icon_button .wrapper .flex .icon .assetImg {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
              align-items: center; }
          .copy_icon_button .wrapper .flex .icon .assetImg img {
            width: 100%;
            border: 3px solid white; }
  @media screen and (max-width: 640px) {
    .copy_icon_button {
      padding: 5rem 2.5rem; }
      .copy_icon_button .wrapper {
        padding-left: 0; }
        .copy_icon_button .wrapper .flex {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
              flex-direction: column; }
          .copy_icon_button .wrapper .flex p {
            padding-right: 0; }
          .copy_icon_button .wrapper .flex .copy {
            padding-right: 0;
            -ms-flex-order: 2;
                order: 2; }
            .copy_icon_button .wrapper .flex .copy .text h2 {
              padding-right: 0; }
            .copy_icon_button .wrapper .flex .copy .text p {
              padding-right: 0; }
            .copy_icon_button .wrapper .flex .copy a {
              width: 55%; }
          .copy_icon_button .wrapper .flex article.icon {
            display: block;
            -ms-flex-order: 2;
                order: 2;
            padding-top: 4rem;
            padding-bottom: 2rem; }
            .copy_icon_button .wrapper .flex article.icon .assetImg img {
              margin: 0 auto;
              width: 60%;
              padding-bottom: 0; }
            .copy_icon_button .wrapper .flex article.icon.aos-init {
              -ms-flex-order: 1;
                  order: 1; }
              .copy_icon_button .wrapper .flex article.icon.aos-init .assetImg {
                -ms-flex-align: start;
                    align-items: flex-start; }
                .copy_icon_button .wrapper .flex article.icon.aos-init .assetImg img {
                  margin-left: 0;
                  width: 80%;
                  padding-bottom: 0; }
              .copy_icon_button .wrapper .flex article.icon.aos-init.video .assetImg img {
                width: 100%; }
                .copy_icon_button .wrapper .flex article.icon.aos-init.video .assetImg img.play {
                  width: 30%; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    .copy_icon_button {
      padding: 3rem 2.5rem; }
      .copy_icon_button .wrapper {
        padding-left: 0; }
        .copy_icon_button .wrapper .flex {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
              flex-direction: column; }
          .copy_icon_button .wrapper .flex .copy .text h2 {
            padding-right: 0; }
          .copy_icon_button .wrapper .flex .copy .text p {
            padding-right: 0; }
          .copy_icon_button .wrapper .flex article.icon {
            display: block;
            padding: 4rem 0; }
            .copy_icon_button .wrapper .flex article.icon .assetImg img {
              margin: 0 auto;
              width: 50%;
              padding-bottom: 0; } }
  @media screen and (min-width: 901px) {
    .copy_icon_button {
      padding: 6rem calc(50vw - 610px); }
      .copy_icon_button.blue {
        padding: 9rem calc(50vw - 610px); } }

.copy_icon_cta {
  background: #1b1d1c;
  background-image: url("http://na-ab19.marketo.com/rs/444-TCD-959/images/01BG2.jpg");
  background-size: 80%;
  background-position: left;
  background-repeat: repeat-x; }
  .copy_icon_cta .wrapper {
    padding-left: 2rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    text-align: left;
    color: #ffffff; }
    .copy_icon_cta .wrapper .flex {
      width: 100%;
      display: -ms-flexbox;
      display: flex; }
      .copy_icon_cta .wrapper .flex:nth-of-type(2), .copy_icon_cta .wrapper .flex:nth-of-type(3) {
        padding-top: 15rem; }
      .copy_icon_cta .wrapper .flex h3 {
        font-family: Courier Prime,monospace;
        color: #00b3e8;
        font-size: 1.4rem; }
      .copy_icon_cta .wrapper .flex h1 {
        padding-top: 1.5rem;
        font-size: 2rem;
        font-weight: 600; }
      .copy_icon_cta .wrapper .flex p {
        padding-top: 1.5rem;
        font-size: 1.1rem;
        line-height: 1.9rem;
        color: #ffffff;
        font-weight: normal;
        padding-right: 3rem;
        padding-bottom: 2rem; }
      .copy_icon_cta .wrapper .flex a {
        color: #00b3e8;
        font-weight: 600;
        font-size: 1rem;
        text-decoration: none;
        border-bottom: 1px solid #00b3e8;
        padding-bottom: 5px; }
      .copy_icon_cta .wrapper .flex .fingerprint {
        -ms-flex: 0 0 40%;
            flex: 0 0 40%; }
        .copy_icon_cta .wrapper .flex .fingerprint #fingerprintImg {
          text-align: center; }
          .copy_icon_cta .wrapper .flex .fingerprint #fingerprintImg img {
            width: 12rem; }
      .copy_icon_cta .wrapper .flex .fingerprintCopy {
        -ms-flex: 0 0 60%;
            flex: 0 0 60%;
        padding-left: 2rem; }
      .copy_icon_cta .wrapper .flex .copy {
        -ms-flex: 0 0 60%;
            flex: 0 0 60%;
        padding-right: 9rem; }
        .copy_icon_cta .wrapper .flex .copy .text {
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; }
          .copy_icon_cta .wrapper .flex .copy .text p {
            padding-right: 0; }
      .copy_icon_cta .wrapper .flex .icon {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: start;
            justify-content: flex-start;
        -ms-flex: 0 0 40%;
            flex: 0 0 40%; }
        .copy_icon_cta .wrapper .flex .icon .CopyIconImage {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
              align-items: center; }
          .copy_icon_cta .wrapper .flex .icon .CopyIconImage img {
            width: 100%; }
  @media screen and (max-width: 640px) {
    .copy_icon_cta {
      padding: 5rem 2.5rem; }
      .copy_icon_cta .wrapper {
        padding-left: 0; }
        .copy_icon_cta .wrapper .flex {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
              flex-direction: column; }
          .copy_icon_cta .wrapper .flex:nth-of-type(2), .copy_icon_cta .wrapper .flex:nth-of-type(3) {
            padding-top: 4rem; }
          .copy_icon_cta .wrapper .flex h3 {
            padding-top: 2rem; }
          .copy_icon_cta .wrapper .flex .fingerprintCopy {
            padding-left: 0; }
          .copy_icon_cta .wrapper .flex p {
            padding-right: 0; }
          .copy_icon_cta .wrapper .flex .copy {
            padding-right: 0;
            -ms-flex-order: 2;
                order: 2; }
            .copy_icon_cta .wrapper .flex .copy .text h2 {
              padding-right: 0; }
            .copy_icon_cta .wrapper .flex .copy .text p {
              padding-right: 0; }
          .copy_icon_cta .wrapper .flex article.icon {
            display: block;
            -ms-flex-order: 1;
                order: 1;
            padding-top: 0rem;
            padding-bottom: 2rem; }
            .copy_icon_cta .wrapper .flex article.icon .CopyIconImage img {
              width: 100%;
              padding-bottom: 0; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    .copy_icon_cta {
      padding: 6rem 2.5rem; }
      .copy_icon_cta .wrapper {
        padding-left: 0; }
        .copy_icon_cta .wrapper .flex {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
              flex-direction: column; }
          .copy_icon_cta .wrapper .flex:nth-of-type(2), .copy_icon_cta .wrapper .flex:nth-of-type(3) {
            padding-top: 4rem; }
          .copy_icon_cta .wrapper .flex h3 {
            padding-top: 2rem; }
          .copy_icon_cta .wrapper .flex .fingerprintCopy {
            padding-left: 0; }
          .copy_icon_cta .wrapper .flex .copy {
            -ms-flex-order: 2;
                order: 2; }
            .copy_icon_cta .wrapper .flex .copy .text h2 {
              padding-right: 0; }
            .copy_icon_cta .wrapper .flex .copy .text p {
              padding-right: 0; }
          .copy_icon_cta .wrapper .flex article.icon {
            display: block;
            padding-bottom: 2rem;
            -ms-flex-order: 1;
                order: 1; }
            .copy_icon_cta .wrapper .flex article.icon .CopyIconImage {
              -ms-flex-pack: center;
                  justify-content: center; }
              .copy_icon_cta .wrapper .flex article.icon .CopyIconImage img {
                width: auto;
                padding-bottom: 0; }
          .copy_icon_cta .wrapper .flex .fingerprint #CogsImg {
            text-align: center; } }
  @media screen and (min-width: 901px) {
    .copy_icon_cta {
      padding: 10rem calc(50vw - 610px); } }

.headline_copy_button {
  background: red;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffffff;
  text-align: left;
  color: #414141;
  min-height: 38rem; }
  .headline_copy_button .flex {
    width: 100%;
    display: -ms-flexbox;
    display: flex; }
    .headline_copy_button .flex .headline {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: start;
          justify-content: flex-start;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex: 0 0 35%;
          flex: 0 0 35%; }
      .headline_copy_button .flex .headline .top {
        display: -ms-flexbox;
        display: flex;
        position: relative;
        width: 100%;
        -ms-flex: 0 0 64.5%;
            flex: 0 0 64.5%;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), black), url("http://na-ab19.marketo.com/rs/444-TCD-959/images/upskill.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top left; }
        .headline_copy_button .flex .headline .top#headlineCopyButtonTitle {
          -ms-flex-align: end;
              align-items: flex-end; }
          .headline_copy_button .flex .headline .top#headlineCopyButtonTitle h1 {
            position: absolute;
            bottom: 15%;
            left: 30%;
            font-size: 2rem;
            font-weight: 400;
            color: #fff; }
            .headline_copy_button .flex .headline .top#headlineCopyButtonTitle h1::after {
              display: block;
              content: "";
              width: 23%;
              height: 5px;
              background: #00b3e8;
              position: absolute;
              top: 50px;
              left: 0; }
      .headline_copy_button .flex .headline .sectionSelectorWrap {
        cursor: pointer;
        background: #000000;
        width: 100%;
        -ms-flex: 0 0 33%;
            flex: 0 0 33%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-align: end;
            align-items: flex-end; }
        .headline_copy_button .flex .headline .sectionSelectorWrap .option {
          margin-bottom: 1px;
          font-size: 1.1rem;
          font-weight: normal;
          padding: 1rem 2rem;
          color: #7d7d7d;
          background: #222222;
          width: 85%; }
          .headline_copy_button .flex .headline .sectionSelectorWrap .option.active {
            background: #00b3e8;
            color: #fff; }
    .headline_copy_button .flex .copy {
      -ms-flex: 0 0 65%;
          flex: 0 0 65%;
      padding-left: 4rem; }
      .headline_copy_button .flex .copy .text {
        display: none;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
        .headline_copy_button .flex .copy .text.active {
          display: block; }
        .headline_copy_button .flex .copy .text h3 {
          color: #1b1d1c;
          font-size: .9rem;
          letter-spacing: 1.5px;
          font-weight: 400; }
        .headline_copy_button .flex .copy .text h1 {
          padding-top: .5rem;
          font-size: 2rem;
          color: #000000;
          font-weight: 600; }
        .headline_copy_button .flex .copy .text p {
          color: #1b1d1c;
          font-size: 1rem;
          line-height: 1.6rem;
          padding-top: 0.2rem;
          padding: 2rem 0;
          font-weight: normal; }
        .headline_copy_button .flex .copy .text a {
          color: #00b3e8;
          font-size: 1.1rem;
          font-weight: bold; }
  @media screen and (max-width: 640px) {
    .headline_copy_button .flex {
      display: block; }
      .headline_copy_button .flex .headline .top {
        min-height: 10rem; }
        .headline_copy_button .flex .headline .top#headlineCopyButtonTitle h1 {
          left: 5%; }
      .headline_copy_button .flex .headline .sectionSelectorWrap .option {
        width: 100%; }
      .headline_copy_button .flex .copy {
        padding: 2rem 1.5rem 3.5rem; }
        .headline_copy_button .flex .copy .text h3 {
          font-size: .8rem; }
        .headline_copy_button .flex .copy .text h1 {
          font-size: 1.5rem; }
        .headline_copy_button .flex .copy .text p {
          width: 100%; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    .headline_copy_button .flex {
      display: block; }
      .headline_copy_button .flex .headline .top {
        min-height: 25rem; }
        .headline_copy_button .flex .headline .top#headlineCopyButtonTitle h1 {
          left: 5%; }
      .headline_copy_button .flex .headline .sectionSelectorWrap .option {
        width: 100%; }
      .headline_copy_button .flex .copy {
        padding: 3rem 2.5rem 4rem; } }
  @media screen and (min-width: 901px) {
    .headline_copy_button .copy {
      padding: 7rem calc(50vw - 610px); } }

section.section-1 {
  margin-top: 82px; }
  @media screen and (max-width: 640px) {
    section.section-1 {
      margin-top: 67px; } }
  section.section-1 .container {
    max-width: 1580px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    background: transparent url("../images/workfront/banner.jpg") no-repeat center;
    background-size: cover; }
    section.section-1 .container .left {
      /*background: transparent url('https://go.proofpoint.com/rs/309-RHV-619/images/section1-left-BG.jpg') no-repeat center;
			background-size: cover;*/
      width: 50%;
      padding: 5vw;
      padding: 17vw 2vw 17vw 14vw; }
      @media screen and (max-width: 767px) {
        section.section-1 .container .left {
          width: 100%; } }
      section.section-1 .container .left h1 {
        font-family: 'Adobe Clean Bold';
        font-size: 3.6vw;
        line-height: 3.95vw;
        color: white;
        font-weight: normal;
        margin-bottom: 2rem;
        /*@media screen and (max-width: 660px) {
					font-size: 6.5vw;
					margin-bottom: 1.5rem;
					br {
						display: none;
					}
				}*/ }
        section.section-1 .container .left h1 span {
          color: #33AEE1; }
        @media screen and (min-width: 1580px) {
          section.section-1 .container .left h1 {
            font-size: 3.6rem;
            line-height: 3.95rem; } }
        @media screen and (max-width: 767px) {
          section.section-1 .container .left h1 {
            font-size: 2.1rem;
            line-height: 2.4rem; } }
        @media screen and (max-width: 470px) {
          section.section-1 .container .left h1 {
            font-size: 1.75rem;
            line-height: 2rem; } }
        section.section-1 .container .left h1 h1, section.section-1 .container .left h1 h2, section.section-1 .container .left h1 h3, section.section-1 .container .left h1 h4, section.section-1 .container .left h1 h5, section.section-1 .container .left h1 h6, section.section-1 .container .left h1 p {
          font-family: 'Fjalla One', sans-serif;
          font-size: 3.5vw;
          color: white;
          font-weight: normal;
          margin-bottom: 2.5rem; }
          section.section-1 .container .left h1 h1 span, section.section-1 .container .left h1 h2 span, section.section-1 .container .left h1 h3 span, section.section-1 .container .left h1 h4 span, section.section-1 .container .left h1 h5 span, section.section-1 .container .left h1 h6 span, section.section-1 .container .left h1 p span {
            color: #33AEE1; }
          @media screen and (min-width: 1360px) {
            section.section-1 .container .left h1 h1, section.section-1 .container .left h1 h2, section.section-1 .container .left h1 h3, section.section-1 .container .left h1 h4, section.section-1 .container .left h1 h5, section.section-1 .container .left h1 h6, section.section-1 .container .left h1 p {
              font-size: 3rem; } }
          @media screen and (max-width: 660px) {
            section.section-1 .container .left h1 h1, section.section-1 .container .left h1 h2, section.section-1 .container .left h1 h3, section.section-1 .container .left h1 h4, section.section-1 .container .left h1 h5, section.section-1 .container .left h1 h6, section.section-1 .container .left h1 p {
              font-size: 6.5vw;
              margin-bottom: 1.5rem; }
              section.section-1 .container .left h1 h1 br, section.section-1 .container .left h1 h2 br, section.section-1 .container .left h1 h3 br, section.section-1 .container .left h1 h4 br, section.section-1 .container .left h1 h5 br, section.section-1 .container .left h1 h6 br, section.section-1 .container .left h1 p br {
                display: none; } }
      section.section-1 .container .left #section1Copy h2, section.section-1 .container .left #section1Copy h3, section.section-1 .container .left #section1Copy h4, section.section-1 .container .left #section1Copy h5, section.section-1 .container .left #section1Copy h6 {
        font-family: 'Fjalla One', sans-serif;
        color: white; }
      section.section-1 .container .left #section1Copy ul {
        margin-left: 1.25rem; }
        section.section-1 .container .left #section1Copy ul li {
          font-family: 'Roboto', sans-serif;
          font-size: 17px;
          font-size: 1.2vw;
          color: white;
          margin-bottom: 1rem; }
          @media screen and (min-width: 1360px) {
            section.section-1 .container .left #section1Copy ul li {
              font-size: 16px; } }
          @media screen and (max-width: 660px) {
            section.section-1 .container .left #section1Copy ul li {
              font-size: 16px; } }
      section.section-1 .container .left p {
        font-family: 'Adobe Clean Regular';
        font-size: 1.65vw;
        color: white;
        margin-bottom: 1rem; }
        @media screen and (min-width: 1580px) {
          section.section-1 .container .left p {
            font-size: 1.65rem;
            line-height: 1.85rem;
            font-style: normal;
            font-weight: 400; } }
        @media screen and (max-width: 660px) {
          section.section-1 .container .left p {
            font-size: 16px; } }
        @media screen and (max-width: 470px) {
          section.section-1 .container .left p {
            width: 49%; } }
      @media screen and (min-width: 1580px) {
        section.section-1 .container .left {
          padding: 17rem 5rem 17rem 14rem; } }
      @media screen and (max-width: 660px) {
        section.section-1 .container .left {
          -ms-flex-order: 1;
              order: 1;
          width: 100%; } }
      @media screen and (max-width: 630px) {
        section.section-1 .container .left {
          padding-left: 3rem; } }
      @media screen and (max-width: 470px) {
        section.section-1 .container .left {
          padding-left: 1.5rem; } }
    section.section-1 .container .right {
      width: 50%; }
      section.section-1 .container .right img {
        width: 100%;
        display: block; }
      @media screen and (max-width: 660px) {
        section.section-1 .container .right {
          -ms-flex-order: 2;
              order: 2;
          width: 100%; } }
      section.section-1 .container .right #mkto_gen_section1-image {
        display: block; }
    section.section-1 .container .footer {
      width: 100%;
      background: #01579b;
      background: linear-gradient(90deg, #01579b 0%, #0290cb 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01579b",endColorstr="#0290cb",GradientType=1); }
      section.section-1 .container .footer h2 {
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-size: 2vw;
        text-align: center;
        color: white;
        padding: 1.5rem 0; }
        @media screen and (min-width: 1360px) {
          section.section-1 .container .footer h2 {
            font-size: 1.7rem; } }
        @media screen and (max-width: 660px) {
          section.section-1 .container .footer h2 {
            font-size: 1.5rem; } }
        @media screen and (max-width: 640px) {
          section.section-1 .container .footer h2 {
            font-size: 20px; } }
      @media screen and (max-width: 660px) {
        section.section-1 .container .footer {
          -ms-flex-order: 3;
              order: 3; } }
    @media screen and (max-width: 660px) {
      section.section-1 .container {
        -ms-flex-direction: column;
            flex-direction: column; } }

section.section-2 {
  /*margin-top: 2rem;*/ }
  section.section-2 .container {
    max-width: 1140px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 3.5rem 0; }
    section.section-2 .container h2 {
      font-family: 'Adobe Clean Bold';
      text-align: center;
      color: #2c2c2c;
      width: 100%;
      font-size: 2.4rem;
      margin-bottom: 1rem; }
      @media screen and (min-width: 1580px) {
        section.section-2 .container h2 {
          font-size: 2.4rem; } }
      @media screen and (max-width: 470px) {
        section.section-2 .container h2 {
          font-size: 1.75rem; } }
    section.section-2 .container p {
      font-family: 'Adobe Clean Regular';
      text-align: center;
      margin: 0 auto;
      margin-bottom: 1rem;
      width: 89.5%;
      color: #2c2c2c; }
    section.section-2 .container a {
      font-family: 'Adobe Clean Bold';
      font-size: 13px;
      font-size: 14px;
      border-radius: 2rem;
      padding: 0.5rem 1rem 0.65rem;
      color: #ffffff;
      display: block;
      text-align: center;
      background: #1473E6;
      /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#3688eb",GradientType=1);*/
      border: 1px solid #1473E6;
      text-transform: uppercase;
      text-decoration: none;
      transition: all 0.3s ease;
      border-radius: 2rem;
      text-transform: none;
      margin: 0 auto;
      margin-top: 0.2rem; }
      section.section-2 .container a:hover {
        transition: all 0.3s ease;
        background: #0d66d0;
        border: 1px solid #0d66d0;
        /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#1373e4",GradientType=1);*/ }
    section.section-2 .container .left {
      width: 33%; }
      section.section-2 .container .left img {
        width: 100%;
        display: block; }
      @media screen and (max-width: 660px) {
        section.section-2 .container .left {
          width: 100%; } }
    section.section-2 .container .right {
      background: transparent url("https://go.proofpoint.com/rs/309-RHV-619/images/section2-right-BG.jpg") no-repeat center;
      background-size: cover;
      width: 65%;
      padding: 4.2vw; }
      section.section-2 .container .right #section2RightText h2, section.section-2 .container .right #section2RightText h3, section.section-2 .container .right #section2RightText h4, section.section-2 .container .right #section2RightText h5, section.section-2 .container .right #section2RightText h6 {
        font-family: 'Fjalla One', sans-serif;
        color: white; }
      section.section-2 .container .right #section2RightText p, section.section-2 .container .right #section2RightText li {
        font-family: 'Roboto', sans-serif;
        font-size: 1.2vw;
        color: white;
        margin-bottom: 1rem; }
        @media screen and (min-width: 1360px) {
          section.section-2 .container .right #section2RightText p, section.section-2 .container .right #section2RightText li {
            font-size: 16px; } }
        @media screen and (max-width: 900px) {
          section.section-2 .container .right #section2RightText p, section.section-2 .container .right #section2RightText li {
            margin-bottom: 0.5rem; } }
        @media screen and (max-width: 660px) {
          section.section-2 .container .right #section2RightText p, section.section-2 .container .right #section2RightText li {
            font-size: 16px; } }
      section.section-2 .container .right p {
        font-family: 'Roboto', sans-serif;
        font-size: 1.2vw;
        color: white;
        margin-bottom: 1rem; }
        @media screen and (min-width: 1360px) {
          section.section-2 .container .right p {
            font-size: 18px;
            font-style: normal;
            line-height: 22px; } }
        @media screen and (max-width: 900px) {
          section.section-2 .container .right p {
            margin-bottom: 0.5rem; } }
        @media screen and (max-width: 660px) {
          section.section-2 .container .right p {
            font-size: 16px; } }
      section.section-2 .container .right a {
        margin-top: 1.5rem;
        display: inline-block; }
        section.section-2 .container .right a.button {
          background: white;
          padding: 1rem 1.5rem;
          font-family: 'Fjalla One', sans-serif;
          font-size: 1.7vw;
          color: #01579B;
          text-decoration: none;
          transition: all 0.3s ease;
          background-image: url(https://go.proofpoint.com/rs/309-RHV-619/images/right-arrow-blue.png);
          background-repeat: no-repeat;
          background-size: 12px;
          background-position: 94% center;
          padding-right: 40px; }
          @media screen and (min-width: 1360px) {
            section.section-2 .container .right a.button {
              font-size: 20px; } }
          @media screen and (max-width: 900px) {
            section.section-2 .container .right a.button {
              margin-top: 1rem; } }
          @media screen and (max-width: 660px) {
            section.section-2 .container .right a.button {
              font-size: 4vw; } }
          section.section-2 .container .right a.button:hover {
            background: #01579B;
            color: #fff;
            transition: all 0.3s ease;
            background-image: url(https://go.proofpoint.com/rs/309-RHV-619/images/right-arrow.png);
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: 94% center; }
      @media screen and (min-width: 1580px) {
        section.section-2 .container .right {
          padding: 3.5rem; } }
      @media screen and (max-width: 660px) {
        section.section-2 .container .right {
          width: 100%;
          margin-top: 2rem; } }
    @media screen and (max-width: 660px) {
      section.section-2 .container {
        -ms-flex-direction: column;
            flex-direction: column; } }

section.section-3 {
  background: #f3f3f3; }
  section.section-3 .container {
    max-width: 1140px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 4rem 0; }
    @media screen and (max-width: 1140px) {
      section.section-3 .container {
        padding-left: 1rem;
        padding-right: 1rem; } }
    section.section-3 .container h2 {
      font-family: 'Adobe Clean Bold';
      text-align: center;
      color: #2c2c2c;
      width: 100%;
      font-size: 2.4rem;
      margin-bottom: 1rem; }
      @media screen and (min-width: 1580px) {
        section.section-3 .container h2 {
          font-size: 2.4rem; } }
      @media screen and (max-width: 470px) {
        section.section-3 .container h2 {
          font-size: 1.75rem; } }
    section.section-3 .container p {
      font-family: 'Adobe Clean Regular';
      text-align: center;
      margin: 0 auto;
      margin-bottom: 1rem;
      width: 89.5%;
      color: #2c2c2c; }
    section.section-3 .container .block {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
          justify-content: space-between;
      margin: 2rem 0;
      -ms-flex-align: center;
          align-items: center; }
      @media screen and (max-width: 940px) {
        section.section-3 .container .block {
          -ms-flex-align: start;
              align-items: flex-start; } }
      @media screen and (max-width: 767px) {
        section.section-3 .container .block {
          -ms-flex-direction: column;
              flex-direction: column; } }
      section.section-3 .container .block .left {
        width: 32.5%; }
        section.section-3 .container .block .left img {
          width: 100%; }
        @media screen and (max-width: 767px) {
          section.section-3 .container .block .left {
            width: 100%;
            margin-bottom: 2rem; } }
      section.section-3 .container .block .right {
        width: 62%; }
        section.section-3 .container .block .right h3 {
          font-family: 'Adobe Clean Bold';
          text-align: left;
          color: #2c2c2c;
          width: 100%;
          font-size: 1.4rem;
          margin-bottom: 1rem; }
          @media screen and (min-width: 1580px) {
            section.section-3 .container .block .right h3 {
              font-size: 1.4rem; } }
          @media screen and (max-width: 767px) {
            section.section-3 .container .block .right h3 {
              text-align: center;
              width: calc(100% - 2rem);
              margin: 0 auto;
              margin-bottom: 1rem; } }
        section.section-3 .container .block .right p {
          font-family: 'Adobe Clean Regular';
          text-align: left;
          margin: 0 auto;
          margin-bottom: 1rem;
          width: 100%;
          color: #2c2c2c; }
          @media screen and (max-width: 767px) {
            section.section-3 .container .block .right p {
              text-align: center;
              width: calc(100% - 2rem);
              margin: 0 auto;
              margin-bottom: 1rem; } }
        section.section-3 .container .block .right a {
          margin-left: 0;
          display: block;
          float: left;
          font-family: 'Adobe Clean Bold';
          font-size: 13px;
          font-size: 14px;
          border-radius: 2rem;
          padding: 0.5rem 1rem 0.65rem;
          color: #ffffff;
          display: block;
          text-align: center;
          background: #1473E6;
          /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#3688eb",GradientType=1);*/
          border: 1px solid #1473E6;
          text-transform: uppercase;
          text-decoration: none;
          transition: all 0.3s ease;
          border-radius: 2rem;
          text-transform: none;
          /*padding-left: 2rem;*/
          /*padding-right: 2rem;*/
          background: transparent;
          border: 2px solid #505050;
          color: #2c2c2c;
          /*font-size: 1.1rem;*/
          margin-top: 1rem;
          transition: all 0.3s ease; }
          section.section-3 .container .block .right a:hover {
            transition: all 0.3s ease;
            background: #0d66d0;
            border: 1px solid #0d66d0;
            /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#1373e4",GradientType=1);*/ }
          section.section-3 .container .block .right a:hover {
            transition: all 0.3s ease;
            color: white;
            background: #323232;
            border: 2px solid #323232; }
          @media screen and (max-width: 767px) {
            section.section-3 .container .block .right a {
              margin-left: auto;
              margin-right: auto;
              float: none;
              max-width: 230px;
              margin-top: 2rem; } }
        @media screen and (max-width: 767px) {
          section.section-3 .container .block .right {
            width: 100%; } }
    @media screen and (max-width: 660px) {
      section.section-3 .container {
        -ms-flex-direction: column;
            flex-direction: column; } }

section.section-4 {
  background: #fff; }
  section.section-4 .container {
    max-width: 1140px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 4rem 0; }
    @media screen and (max-width: 1140px) {
      section.section-4 .container {
        padding-left: 1rem;
        padding-right: 1rem; } }
    section.section-4 .container h2 {
      font-family: 'Adobe Clean Bold';
      text-align: center;
      color: #2c2c2c;
      width: 100%;
      font-size: 2.4rem;
      margin-bottom: 1rem; }
      @media screen and (min-width: 1580px) {
        section.section-4 .container h2 {
          font-size: 2.4rem; } }
      @media screen and (max-width: 470px) {
        section.section-4 .container h2 {
          font-size: 1.75rem; } }
    section.section-4 .container p {
      font-family: 'Adobe Clean Regular';
      text-align: center;
      margin: 0 auto;
      margin-bottom: 1rem;
      width: 89.5%;
      color: #2c2c2c; }
    section.section-4 .container .block {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
          justify-content: space-between;
      margin: 2rem 0;
      -ms-flex-align: center;
          align-items: center; }
      @media screen and (max-width: 940px) {
        section.section-4 .container .block {
          -ms-flex-align: start;
              align-items: flex-start; } }
      @media screen and (max-width: 767px) {
        section.section-4 .container .block {
          -ms-flex-direction: column;
              flex-direction: column; } }
      section.section-4 .container .block .left {
        width: 32.5%; }
        section.section-4 .container .block .left img {
          width: 100%; }
        @media screen and (max-width: 767px) {
          section.section-4 .container .block .left {
            width: 100%;
            margin-bottom: 2rem; } }
      section.section-4 .container .block .right {
        width: 62%; }
        section.section-4 .container .block .right h3 {
          font-family: 'Adobe Clean Bold';
          text-align: left;
          color: #2c2c2c;
          width: 100%;
          font-size: 1.4rem;
          margin-bottom: 1rem; }
          @media screen and (min-width: 1580px) {
            section.section-4 .container .block .right h3 {
              font-size: 1.4rem; } }
          @media screen and (max-width: 767px) {
            section.section-4 .container .block .right h3 {
              text-align: center;
              width: calc(100% - 2rem);
              margin: 0 auto;
              margin-bottom: 1rem; } }
        section.section-4 .container .block .right p {
          font-family: 'Adobe Clean Regular';
          text-align: left;
          margin: 0 auto;
          margin-bottom: 1rem;
          width: 100%;
          color: #2c2c2c; }
          @media screen and (max-width: 767px) {
            section.section-4 .container .block .right p {
              text-align: center;
              width: calc(100% - 2rem);
              margin: 0 auto;
              margin-bottom: 1rem; } }
        section.section-4 .container .block .right a {
          margin-left: 0;
          display: block;
          float: left;
          font-family: 'Adobe Clean Bold';
          font-size: 13px;
          font-size: 14px;
          border-radius: 2rem;
          padding: 0.5rem 1rem 0.65rem;
          color: #ffffff;
          display: block;
          text-align: center;
          background: #1473E6;
          /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#3688eb",GradientType=1);*/
          border: 1px solid #1473E6;
          text-transform: uppercase;
          text-decoration: none;
          transition: all 0.3s ease;
          border-radius: 2rem;
          text-transform: none;
          /*padding-left: 2rem;*/
          /*padding-right: 2rem;*/
          background: transparent;
          border: 2px solid #505050;
          color: #2c2c2c;
          /*font-size: 1.1rem;*/
          margin-top: 1rem;
          transition: all 0.3s ease; }
          section.section-4 .container .block .right a:hover {
            transition: all 0.3s ease;
            background: #0d66d0;
            border: 1px solid #0d66d0;
            /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#1373e4",GradientType=1);*/ }
          section.section-4 .container .block .right a:hover {
            transition: all 0.3s ease;
            color: white;
            background: #323232;
            border: 2px solid #323232; }
          @media screen and (max-width: 767px) {
            section.section-4 .container .block .right a {
              margin-left: auto;
              margin-right: auto;
              float: none;
              max-width: 230px;
              margin-top: 2rem; } }
        @media screen and (max-width: 767px) {
          section.section-4 .container .block .right {
            width: 100%; } }
    section.section-4 .container .block.right .left {
      -ms-flex-order: 2;
          order: 2; }
      @media screen and (max-width: 767px) {
        section.section-4 .container .block.right .left {
          -ms-flex-order: 1;
              order: 1; } }
    section.section-4 .container .block.right .right {
      -ms-flex-order: 1;
          order: 1; }
      @media screen and (max-width: 767px) {
        section.section-4 .container .block.right .right {
          -ms-flex-order: 2;
              order: 2; } }
    @media screen and (max-width: 660px) {
      section.section-4 .container {
        -ms-flex-direction: column;
            flex-direction: column; } }

section.section-5 {
  background: #f3f3f3; }
  section.section-5 .container {
    max-width: 1140px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 4rem 0; }
    @media screen and (max-width: 1140px) {
      section.section-5 .container {
        padding-left: 1rem;
        padding-right: 1rem; } }
    section.section-5 .container h2 {
      font-family: 'Adobe Clean Bold';
      text-align: center;
      color: #2c2c2c;
      width: 100%;
      font-size: 2.4rem;
      margin-bottom: 1rem; }
      @media screen and (min-width: 1580px) {
        section.section-5 .container h2 {
          font-size: 2.4rem; } }
      @media screen and (max-width: 470px) {
        section.section-5 .container h2 {
          font-size: 1.75rem; } }
    section.section-5 .container p {
      font-family: 'Adobe Clean Regular';
      text-align: center;
      margin: 0 auto;
      margin-bottom: 1rem;
      width: 70.5%;
      color: #2c2c2c; }
    section.section-5 .container .block {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
          justify-content: space-between;
      margin: 2rem 0;
      -ms-flex-align: center;
          align-items: center; }
      @media screen and (max-width: 940px) {
        section.section-5 .container .block {
          -ms-flex-align: start;
              align-items: flex-start; } }
      @media screen and (max-width: 767px) {
        section.section-5 .container .block {
          -ms-flex-direction: column;
              flex-direction: column; } }
      section.section-5 .container .block .left {
        width: 32.5%; }
        section.section-5 .container .block .left img {
          width: 100%; }
        @media screen and (max-width: 767px) {
          section.section-5 .container .block .left {
            width: 100%;
            margin-bottom: 2rem; } }
      section.section-5 .container .block .right {
        width: 62%; }
        section.section-5 .container .block .right h3 {
          font-family: 'Adobe Clean Bold';
          text-align: left;
          color: #2c2c2c;
          width: 100%;
          font-size: 1.4rem;
          margin-bottom: 1rem; }
          @media screen and (min-width: 1580px) {
            section.section-5 .container .block .right h3 {
              font-size: 1.4rem; } }
          @media screen and (max-width: 767px) {
            section.section-5 .container .block .right h3 {
              text-align: center;
              width: calc(100% - 2rem);
              margin: 0 auto;
              margin-bottom: 1rem; } }
        section.section-5 .container .block .right p {
          font-family: 'Adobe Clean Regular';
          text-align: left;
          margin: 0 auto;
          margin-bottom: 1rem;
          width: 100%;
          color: #2c2c2c; }
          @media screen and (max-width: 767px) {
            section.section-5 .container .block .right p {
              text-align: center;
              width: calc(100% - 2rem);
              margin: 0 auto;
              margin-bottom: 1rem; } }
        section.section-5 .container .block .right a {
          margin-left: 0;
          display: block;
          float: left;
          font-family: 'Adobe Clean Bold';
          font-size: 13px;
          font-size: 14px;
          border-radius: 2rem;
          padding: 0.5rem 1rem 0.65rem;
          color: #ffffff;
          display: block;
          text-align: center;
          background: #1473E6;
          /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(54,136,235,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#3688eb",GradientType=1);*/
          border: 1px solid #1473E6;
          text-transform: uppercase;
          text-decoration: none;
          transition: all 0.3s ease;
          border-radius: 2rem;
          text-transform: none;
          /*padding-left: 2rem;*/
          /*padding-right: 2rem;*/
          background: transparent;
          border: 2px solid #505050;
          color: #2c2c2c;
          /*font-size: 1.1rem;*/
          margin-top: 1rem;
          transition: all 0.3s ease; }
          section.section-5 .container .block .right a:hover {
            transition: all 0.3s ease;
            background: #0d66d0;
            border: 1px solid #0d66d0;
            /*background: -moz-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        background: linear-gradient(0deg, rgba(19,115,228,1) 0%, rgba(19,115,228,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1373e4",endColorstr="#1373e4",GradientType=1);*/ }
          section.section-5 .container .block .right a:hover {
            transition: all 0.3s ease;
            color: white;
            background: #323232;
            border: 2px solid #323232; }
          @media screen and (max-width: 767px) {
            section.section-5 .container .block .right a {
              margin-left: auto;
              margin-right: auto;
              float: none;
              max-width: 230px;
              margin-top: 2rem; } }
        @media screen and (max-width: 767px) {
          section.section-5 .container .block .right {
            width: 100%; } }
    @media screen and (max-width: 660px) {
      section.section-5 .container {
        -ms-flex-direction: column;
            flex-direction: column; } }

section.section-6 {
  background: #ec1000; }
  section.section-6 .container {
    max-width: 1360px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    section.section-6 .container h2 {
      color: white;
      width: 100%;
      text-align: center;
      font-family: 'Adobe Clean Bold';
      font-size: 2.4rem;
      padding: 3.5rem 0; }
      @media screen and (min-width: 1580px) {
        section.section-6 .container h2 {
          font-size: 2.4rem; } }
      @media screen and (max-width: 470px) {
        section.section-6 .container h2 {
          font-size: 1.75rem;
          width: calc(100% - 2rem);
          margin: 0 auto; } }
    section.section-6 .container .left {
      width: 45%;
      padding-top: 5rem; }
      section.section-6 .container .left a {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #01579B;
        text-align: center;
        display: block; }
      section.section-6 .container .left img {
        width: 80%;
        margin: 0 auto;
        display: block; }
      @media screen and (max-width: 660px) {
        section.section-6 .container .left {
          width: 100%; } }
    section.section-6 .container .right {
      width: 55%;
      padding: 5rem;
      padding-right: 0.5rem; }
      section.section-6 .container .right h3 {
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        color: #01579B; }
        section.section-6 .container .right h3 h1, section.section-6 .container .right h3 h2, section.section-6 .container .right h3 h3, section.section-6 .container .right h3 h4, section.section-6 .container .right h3 h5, section.section-6 .container .right h3 h6, section.section-6 .container .right h3 p {
          font-family: 'Roboto', sans-serif;
          font-size: 13px;
          color: #01579B; }
      section.section-6 .container .right h2 {
        font-family: 'Fjalla One', sans-serif;
        font-size: 38px;
        color: #01579B;
        font-weight: normal;
        margin-bottom: 2.5rem; }
        section.section-6 .container .right h2 h1, section.section-6 .container .right h2 h2, section.section-6 .container .right h2 h3, section.section-6 .container .right h2 h4, section.section-6 .container .right h2 h5, section.section-6 .container .right h2 h6, section.section-6 .container .right h2 p {
          font-family: 'Fjalla One', sans-serif;
          font-size: 38px;
          color: #01579B;
          font-weight: normal;
          margin-bottom: 2.5rem; }
          @media screen and (max-width: 660px) {
            section.section-6 .container .right h2 h1, section.section-6 .container .right h2 h2, section.section-6 .container .right h2 h3, section.section-6 .container .right h2 h4, section.section-6 .container .right h2 h5, section.section-6 .container .right h2 h6, section.section-6 .container .right h2 p {
              font-size: 6.5vw; } }
        @media screen and (max-width: 660px) {
          section.section-6 .container .right h2 {
            font-size: 6.5vw; } }
      section.section-6 .container .right #Section6RightCopy h2, section.section-6 .container .right #Section6RightCopy h3, section.section-6 .container .right #Section6RightCopy h4, section.section-6 .container .right #Section6RightCopy h5, section.section-6 .container .right #Section6RightCopy h6 {
        font-family: 'Fjalla One', sans-serif;
        color: #333333; }
      section.section-6 .container .right #Section6RightCopy p {
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        color: #333333;
        margin-bottom: 1rem; }
      section.section-6 .container .right #Section6RightCopy ul {
        margin-left: 1.25rem;
        margin-bottom: 1rem; }
        section.section-6 .container .right #Section6RightCopy ul li {
          ffont-family: 'Roboto', sans-serif;
          font-size: 15px;
          color: #333333; }
      section.section-6 .container .right p {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #333333;
        margin-bottom: 1rem; }
      section.section-6 .container .right ul {
        margin-left: 1.25rem;
        margin-bottom: 1rem; }
        section.section-6 .container .right ul li {
          font-family: 'Roboto', sans-serif;
          font-size: 18px;
          line-height: 22px;
          color: #333333; }
      section.section-6 .container .right a {
        margin-top: 1.5rem;
        display: inline-block; }
        section.section-6 .container .right a.button {
          background: white;
          padding: 1rem 1.5rem;
          font-family: 'Fjalla One', sans-serif;
          font-size: 20px;
          color: #01579B;
          text-decoration: none; }
      section.section-6 .container .right img {
        width: 100%;
        display: block; }
      @media screen and (max-width: 660px) {
        section.section-6 .container .right {
          width: 100%;
          padding: 4.2vw; } }
    section.section-6 .container .footer {
      width: 100%;
      padding: 5rem;
      background: #01579b;
      background: linear-gradient(90deg, #01579b 0%, #0290cb 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01579b",endColorstr="#0290cb",GradientType=1); }
      section.section-6 .container .footer h2 {
        font-family: 'Fjalla One', sans-serif;
        font-size: 20px;
        color: white;
        font-weight: normal; }
      section.section-6 .container .footer .statsHolder {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
            justify-content: space-between;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        margin-top: 1.5rem; }
        section.section-6 .container .footer .statsHolder .stat {
          width: 31.5%;
          background-color: white;
          padding: 2rem;
          position: relative;
          overflow: hidden;
          background: linear-gradient(45deg, transparent 10px, white 10px); }
          section.section-6 .container .footer .statsHolder .stat p {
            font-family: 'Fjalla One', sans-serif;
            font-size: 17px;
            color: #01579B;
            font-weight: normal; }
    @media screen and (max-width: 660px) {
      section.section-6 .container {
        -ms-flex-direction: column;
            flex-direction: column; } }

section.section-7 {
  margin-top: 0rem; }
  section.section-7 .container {
    max-width: 1360px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    background: #ffffff;
    border: 7px solid #F7F8F9; }
    section.section-7 .container .left {
      width: 55%;
      padding: 5rem;
      padding-right: 0.5rem; }
      section.section-7 .container .left h3 {
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        color: #01579B; }
        section.section-7 .container .left h3 h1, section.section-7 .container .left h3 h2, section.section-7 .container .left h3 h3, section.section-7 .container .left h3 h4, section.section-7 .container .left h3 h5, section.section-7 .container .left h3 h6, section.section-7 .container .left h3 p {
          font-family: 'Roboto', sans-serif;
          font-size: 13px;
          color: #01579B; }
      section.section-7 .container .left h2 {
        font-family: 'Fjalla One', sans-serif;
        font-size: 38px;
        color: #01579B;
        font-weight: normal;
        margin-bottom: 2.5rem; }
        section.section-7 .container .left h2 h1, section.section-7 .container .left h2 h2, section.section-7 .container .left h2 h3, section.section-7 .container .left h2 h4, section.section-7 .container .left h2 h5, section.section-7 .container .left h2 h6, section.section-7 .container .left h2 p {
          font-family: 'Fjalla One', sans-serif;
          font-size: 38px;
          color: #01579B;
          font-weight: normal;
          margin-bottom: 2.5rem; }
          @media screen and (max-width: 660px) {
            section.section-7 .container .left h2 h1, section.section-7 .container .left h2 h2, section.section-7 .container .left h2 h3, section.section-7 .container .left h2 h4, section.section-7 .container .left h2 h5, section.section-7 .container .left h2 h6, section.section-7 .container .left h2 p {
              font-size: 6.5vw; } }
        @media screen and (max-width: 660px) {
          section.section-7 .container .left h2 {
            font-size: 6.5vw; } }
      section.section-7 .container .left #Section7LeftCopy h2, section.section-7 .container .left #Section7LeftCopy h3, section.section-7 .container .left #Section7LeftCopy h4, section.section-7 .container .left #Section7LeftCopy h5, section.section-7 .container .left #Section7LeftCopy h6 {
        font-family: 'Fjalla One', sans-serif;
        color: #333333; }
      section.section-7 .container .left #Section7LeftCopy p {
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        color: #333333;
        margin-bottom: 1rem; }
        @media screen and (max-width: 1154px) {
          section.section-7 .container .left #Section7LeftCopy p br {
            display: none; } }
      section.section-7 .container .left #Section7LeftCopy ul {
        margin-left: 1.25rem;
        margin-bottom: 1rem; }
        section.section-7 .container .left #Section7LeftCopy ul li {
          font-family: 'Roboto', sans-serif;
          font-size: 15px;
          color: #333333; }
      section.section-7 .container .left p {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #333333;
        margin-bottom: 1rem; }
        @media screen and (max-width: 1154px) {
          section.section-7 .container .left p br {
            display: none; } }
      section.section-7 .container .left ul {
        margin-left: 1.25rem;
        margin-bottom: 1rem; }
        section.section-7 .container .left ul li {
          font-family: 'Roboto', sans-serif;
          font-size: 18px;
          line-height: 22px;
          color: #333333; }
      section.section-7 .container .left a {
        margin-top: 1.5rem;
        display: inline-block; }
        section.section-7 .container .left a.button {
          background: white;
          padding: 1rem 1.5rem;
          font-family: 'Fjalla One', sans-serif;
          font-size: 20px;
          color: #01579B;
          text-decoration: none; }
      section.section-7 .container .left img {
        width: 100%;
        display: block; }
      @media screen and (max-width: 660px) {
        section.section-7 .container .left {
          width: 100%;
          padding: 4.2vw;
          -ms-flex-order: 2;
              order: 2; } }
    section.section-7 .container .right {
      width: 45%;
      padding-top: 5rem; }
      section.section-7 .container .right a {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #01579B;
        text-align: center;
        display: block; }
      section.section-7 .container .right img {
        width: 80%;
        margin: 0 auto;
        display: block; }
      @media screen and (max-width: 660px) {
        section.section-7 .container .right {
          width: 100%; } }
    section.section-7 .container .footer {
      width: 100%;
      padding: 5rem;
      background: #01579b;
      background: linear-gradient(90deg, #01579b 0%, #0290cb 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01579b",endColorstr="#0290cb",GradientType=1); }
      section.section-7 .container .footer h2 {
        font-family: 'Fjalla One', sans-serif;
        font-size: 20px;
        color: white;
        font-weight: normal; }
      section.section-7 .container .footer .statsHolder {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
            justify-content: space-between;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        margin-top: 1.5rem; }
        section.section-7 .container .footer .statsHolder .stat {
          width: 31.5%;
          background-color: white;
          padding: 2rem;
          position: relative;
          overflow: hidden;
          background: linear-gradient(45deg, transparent 10px, white 10px); }
          section.section-7 .container .footer .statsHolder .stat p {
            font-family: 'Fjalla One', sans-serif;
            font-size: 17px;
            color: #01579B;
            font-weight: normal; }
    @media screen and (max-width: 660px) {
      section.section-7 .container {
        -ms-flex-direction: column;
            flex-direction: column; } }

section.section-8 {
  margin-top: 0rem; }
  section.section-8 .container {
    max-width: 1360px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 4rem;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    background: #01579b;
    background: linear-gradient(0deg, #01579b 0%, #0290cb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01579b",endColorstr="#0290cb",GradientType=1); }
    section.section-8 .container .left {
      width: 45%;
      padding-top: 5rem; }
      section.section-8 .container .left a {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #01579B;
        text-align: center;
        display: block; }
      section.section-8 .container .left img {
        width: 80%;
        margin: 0 auto;
        display: block; }
      @media screen and (max-width: 660px) {
        section.section-8 .container .left {
          width: 100%; } }
    section.section-8 .container .right {
      width: 55%;
      padding: 5rem;
      padding-right: 0.5rem; }
      section.section-8 .container .right h3 {
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        color: #01579B; }
      section.section-8 .container .right h2 {
        font-family: 'Fjalla One', sans-serif;
        font-size: 38px;
        color: white;
        font-weight: normal;
        margin-bottom: 2.5rem; }
        section.section-8 .container .right h2 h1, section.section-8 .container .right h2 h2, section.section-8 .container .right h2 h3, section.section-8 .container .right h2 h4, section.section-8 .container .right h2 h5, section.section-8 .container .right h2 h6, section.section-8 .container .right h2 p {
          font-family: 'Fjalla One', sans-serif;
          font-size: 38px;
          color: white;
          font-weight: normal;
          margin-bottom: 2.5rem; }
          @media screen and (max-width: 660px) {
            section.section-8 .container .right h2 h1, section.section-8 .container .right h2 h2, section.section-8 .container .right h2 h3, section.section-8 .container .right h2 h4, section.section-8 .container .right h2 h5, section.section-8 .container .right h2 h6, section.section-8 .container .right h2 p {
              font-size: 6.5vw; } }
        @media screen and (max-width: 660px) {
          section.section-8 .container .right h2 {
            font-size: 6.5vw; } }
      section.section-8 .container .right #Section8RightCopy h2, section.section-8 .container .right #Section8RightCopy h3, section.section-8 .container .right #Section8RightCopy h4, section.section-8 .container .right #Section8RightCopy h5, section.section-8 .container .right #Section8RightCopy h6 {
        font-family: 'Fjalla One', sans-serif;
        color: #ffffff; }
      section.section-8 .container .right #Section8RightCopy p {
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        color: #ffffff;
        margin-bottom: 1rem; }
      section.section-8 .container .right #Section8RightCopy ul {
        margin-left: 1.25rem;
        margin-bottom: 1rem; }
        section.section-8 .container .right #Section8RightCopy ul li {
          font-family: 'Roboto', sans-serif;
          font-size: 15px;
          color: #333333; }
      section.section-8 .container .right p {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        margin-bottom: 1rem; }
      section.section-8 .container .right ul {
        margin-left: 1.25rem;
        margin-bottom: 1rem; }
        section.section-8 .container .right ul li {
          font-family: 'Roboto', sans-serif;
          font-size: 15px;
          color: #333333; }
      section.section-8 .container .right .buttons a:nth-child(2) {
        background: #33AEE1;
        color: white;
        margin-left: 1rem; }
        @media screen and (max-width: 436px) {
          section.section-8 .container .right .buttons a:nth-child(2) {
            margin-left: 0; } }
      section.section-8 .container .right a {
        margin-top: 1.5rem;
        display: inline-block; }
        section.section-8 .container .right a.button {
          background: white;
          padding: 1rem 1.5rem;
          font-family: 'Fjalla One', sans-serif;
          font-size: 20px;
          color: #01579B;
          text-decoration: none; }
      section.section-8 .container .right img {
        width: 100%;
        display: block; }
      @media screen and (max-width: 660px) {
        section.section-8 .container .right {
          width: 100%;
          padding: 4.2vw; } }
    section.section-8 .container .footer {
      width: 100%;
      padding: 5rem;
      background: #01579b;
      background: linear-gradient(90deg, #01579b 0%, #0290cb 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01579b",endColorstr="#0290cb",GradientType=1); }
      section.section-8 .container .footer h2 {
        font-family: 'Fjalla One', sans-serif;
        font-size: 20px;
        color: white;
        font-weight: normal; }
      section.section-8 .container .footer .statsHolder {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
            justify-content: space-between;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        margin-top: 1.5rem; }
        section.section-8 .container .footer .statsHolder .stat {
          width: 31.5%;
          background-color: white;
          padding: 2rem;
          position: relative;
          overflow: hidden;
          background: linear-gradient(45deg, transparent 10px, white 10px); }
          section.section-8 .container .footer .statsHolder .stat p {
            font-family: 'Fjalla One', sans-serif;
            font-size: 17px;
            color: #01579B;
            font-weight: normal; }
    @media screen and (max-width: 660px) {
      section.section-8 .container {
        -ms-flex-direction: column;
            flex-direction: column; } }

.formModal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
  background: rgba(0, 0, 0, 0.45);
  z-index: 2005; }
  .formModal.active {
    display: block; }
  .formModal .close {
    position: absolute;
    left: 1rem;
    top: 1rem;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid white;
    width: 24px;
    height: 24px;
    border-radius: 40px; }
    .formModal .close a {
      color: white;
      text-decoration: none;
      width: 40px;
      height: 40px;
      display: block;
      text-align: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: center;
          justify-content: center; }
  .formModal .container {
    width: 100% !important;
    max-width: 400px;
    min-width: 32.9375rem;
    max-width: 35.75rem;
    margin: 0 auto;
    margin-top: 4rem;
    padding: 3.25rem;
    background: white;
    position: relative; }
    .formModal .container form {
      width: 100% !important; }
      .formModal .container form .mktoFormRow .mktoFormCol {
        width: 100%;
        margin-bottom: 0 !important; }
        .formModal .container form .mktoFormRow .mktoFormCol .mktoOffset {
          display: none; }
        .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap {
          width: 100%; }
          .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap .mktoAsterix {
            display: none; }
          .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap input, .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap select {
            width: 100% !important;
            border-radius: none;
            border: 0;
            padding: 0.5rem;
            padding-left: 0;
            background: white;
            font-family: "Fjalla One", sans-serif;
            font-size: 1.125rem;
            color: #1798D9;
            border-bottom: 2px solid #1798D9; }
            .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap input::-webkit-input-placeholder, .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap input::placeholder, .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap select::-webkit-input-placeholder, .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap select::placeholder {
              color: #1798D9; }
            @media screen and (max-width: 480px) {
              .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap input, .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap select {
                font-size: 14px;
                padding: 0.95rem; } }
          @media screen and (max-width: 480px) {
            .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap select {
              padding: 0.4rem; } }
          .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap label {
            position: relative;
            text-indent: -9999px;
            max-height: 8px;
            width: 100% !important; }
            .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap label .mktoAsterix {
              position: absolute;
              right: 0;
              top: -3px;
              text-indent: 0; }
          .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap .mktoHtmlText {
            width: 100% !important;
            color: white;
            padding-bottom: 1rem; }
            .formModal .container form .mktoFormRow .mktoFormCol .mktoFieldWrap .mktoHtmlText a {
              color: white;
              padding: 0; }
        @media screen and (max-width: 480px) {
          .formModal .container form .mktoFormRow .mktoFormCol {
            margin-bottom: 0.5rem !important; } }
      .formModal .container form .mktoButtonRow .mktoButtonWrap {
        margin-left: 0 !important; }
        .formModal .container form .mktoButtonRow .mktoButtonWrap button {
          font-family: "Fjalla One", sans-serif;
          font-size: 16px;
          font-weight: bold;
          background: #EE3124;
          border: 0;
          /*min-width: 150px;*/
          padding: 0.875rem 1.875rem; }

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  overflow-x: hidden; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Adobe Clean Regular';
  overflow-x: hidden; }

button, input, textarea {
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
          appearance: none;
  border: none;
  outline: none;
  background: none; }

button {
  cursor: pointer; }

a > img {
  border: none; }

h2.contactsHeader {
  text-align: center;
  padding: 2% 0;
  color: #c00;
  padding-bottom: 3.5rem; }

.mktoGen {
  width: 100%; }
